<template>
  <div class="duer-container">
    <!-- 来电弹窗 -->
    <div
      role="dialog"
      class="modal incoming-come-call"
      ref="incoming-call-dialog"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="usr-info">
              <img
                class="usr-pho"
                src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-7/1658388218664/pho.png"
              />
              <!--用于来电展示，接入方通过来电sn查询获取来电姓名 -->
              <p class="usr-name">未知来电</p>
              <p class="incoming-call-tip">邀请您通话</p>
            </div>
            <div class="op-btns">
              <div class="incoming-call-reject">
                <img
                  @click="rejectCall"
                  src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661433175110/reject.png"
                />
                <p class="op-txt">挂断</p>
              </div>
              <div class="incoming-call-video">
                <img
                  @click="answerCall(0)"
                  src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661433226356/videoCall.png"
                />
                <p class="op-txt">视频通话</p>
              </div>
              <div class="incoming-call-audio">
                <img
                  @click="answerCall(1)"
                  src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661433255465/audioCall.png"
                />
                <p class="op-txt">语音通话</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 通话界面 -->
    <div id="call-view" style="display: none;">
        <div class="peer-view main-view">
            <video id='video-peer' autoPlay></video>
            <audio id='audio-peer' autoPlay></audio>
            <div id="peer-audio-tip-wrap" class="audio-tip-wrap">
                <!-- <p class="audio-name online-ellipsis">对端姓名</p> -->
                <p class="audio-call">语音通话中</p>
            </div>
        </div>
        <div class="local-view sub-view">
            <video id='video-local' autoPlay></video>
            <audio id='audio-local' autoPlay></audio>
            <div id="local-audio-tip-wrap" class="audio-tip-wrap">
                <p class="audio-name online-ellipsis">本机</p>
                <p class="audio-call">语音通话中</p>
            </div>
        </div>
        <div class="op-btns">
            <div class="mute-change-btn">
                <img
                    id="mute-icon"
                    @click="changeMute()"
                    src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661515279806/quite0.png"
                >
                <p id="mute-text" class="a-mute">静音</p>
            </div>
            <div class="mode-change-btn">
                <img
                    id="mode-change-icon"
                    @click="changeMode()"
                    src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661828922567/videoCall0.png"
                >
                <p id="mode-change-text" class="v-mode">视频通话</p>
            </div>
            <div>
                <img
                    @click="dropCall()"
                    src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661433175110/reject.png"
                >
                <p style="color: #E24940">挂断</p>
            </div>
        </div>
    </div>
    <!-- 来电音乐 -->
    <audio id="ring-music" loop>
      <source
        src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-9/1662024495462/ring_new.mp3"
        type="audio/mpeg"
      />
    </audio>
    <!-- 去电响铃 -->
    <div id="outing-call-view" style="display: none;">
        <div class="peer-info">
            <img class="peer-pho" src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-7/1658388218664/pho.png">
            <!-- 业务方根据实际呼叫用户进行替换 -->
            <p class="peer-name">被叫名字</p>
            <p class="outing-call-tip">设备正在响铃中，请不要挂断...</p>
        </div>
        <div class="op-btns">
            <div class="out-call-mode">
                <img
                    @click="changeMode()"
                    src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661828922567/videoCall0.png"
                >
                <p class="out-call-mode-txt">视频呼叫</p>
            </div>
            <div>
                <img
                    @click="dropCall()"
                    src="https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661433175110/reject.png"
                >
                <p style="color: #E24940">挂断</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
const duerRtcSdk = window.DUER_rtcsdk;
let duerRtc = null; // rtc对象
const appId = "c5s8ctmf"; // 应用程序唯一标识，这里是rtc_appid，邮件分配的
// const uid = 10000; // 用户唯一标识
const appName = "test_1"; // 应用名称
const enbaleRtcDebugLog = true; // 是否打印日志，建议线下调试设置为true，上线设置为 false
let callInfo = {
  mute: false,
  mode: duerRtcSdk.CallMode.AUDIO_VIDEO,
};
import { getRtcToken } from "@/api/duer";
export default {
  data() {
    return {
      token: null,
    };
  },
  methods: {
    async init(appId, uid) {
      // 构造参数建立长连接参数
      /**
       * uri RTC呼叫系统唯一标识定义
       * @param uid 应用用户唯一标识
       * @param appId 应用程序唯一标识，这里是rtc_appid，邮件分配的
       */
      this.token = await getRtcToken(uid);
      console.log("token", this.token);
      const loginParams = {
        // uri,
        uid: uid,
        appId: appId,
        appName: appName,
        token: this.token,
      };
      // 开启事件回调监听器
      this.rtcListener(loginParams);
    },
    async getRtcToken(uid) {
      return await getRtcToken(uid);
    },
    // 设置事件回调监听器
    rtcListener(loginParams) {
      duerRtc = new duerRtcSdk.RtcContext(
        {
          onAuthStatusChanged: (auth) => {
            if (auth.success) {
              console.log("auth.success");
              // this.setState({ isLogin: true })
            } else {
              switch (auth.code) {
                case duerRtcSdk.AuthCode.PARAM_ERROR:
                case duerRtcSdk.AuthCode.APPID_INVALID: {
                  // 联调阶段可能遇到的错误
                  break;
                }
                case duerRtcSdk.AuthCode.TOKEN_INVALID:
                case duerRtcSdk.AuthCode.TOKEN_EXPIRED: {
                  this.token = this.getRtcToken(this.uid);
                  // 重新刷新token
                  break;
                }
                case duerRtcSdk.AuthCode.NO_ERROR:
                case duerRtcSdk.AuthCode.OTHER_DEVICE_LOGIN: {
                  break;
                }
              }
            }
          },
        },
        enbaleRtcDebugLog
      );
      duerRtc.setCallListener({
        onIncomingCall: (intent) => {
          const serviceInfo =
            (intent.serviceInfo && JSON.parse(intent.serviceInfo)) || {};
          intent.serviceInfo = serviceInfo;
          Object.assign(callInfo, intent);
          this.incomingViewShow();
          console.log("onIncomingCall", callInfo);
        },
        onAddConfereeStateChanged: (state) => {
          console.log("onAddConfereeStateChanged", state);
        },
        onCallReplaced: (replaced) => {
          console.log("onCallReplaced", replaced);
        },
        onCallStateChanged: (stateInfo) => {
          if (stateInfo.state === duerRtcSdk.CallState.RINGBACK) {
            // 被叫开始响铃
            this.ringViewShow();
            this.$emit("close-call");
          } else if (stateInfo.state === duerRtcSdk.CallState.DISCONNECTED) {
            // 通话已经断开
            this.freeCallView();
            if (stateInfo.reason === duerRtcSdk.CallReason.TIMEOUT) {
              // 呼叫超时
              this.callTimeout();
            }
          } else if (stateInfo.state === duerRtcSdk.CallState.CONNECTED) {
            // 通话被正常接听
            this.callViewShow();
          }
          console.log("onCallStateChanged", stateInfo);
        },
        onConfereeLayout: (layouts) => {
          this.setLayouts(layouts);
          console.log("onConfereeLayout", layouts);
        },
        onRecordStateChanged: (recordStateInfo) => {
          console.log("onRecordStateChanged", recordStateInfo);
        },
      });
      duerRtc.setConnectListener({
          onConnectionChanged: conn => {
              if (conn === 3) {
                  // 长链接已断开
                  duerRtc = null;
                  // 更新父组件的登录状态
                  this.$emit("login-status", false);
                  console.log("已断开登录：", conn);
              } else if (conn === 2) {
                this.$emit("login-status", true);
              }
          }
      });
      duerRtc.setMediaDeviceListener({
          // 麦克风/摄像头状态
          onMediaDeviceStateChanged: (device, state, error) => {
              console.log('onMediaDeviceStateChanged', device, state, error);
          }
        });
        this.rtcConnect(loginParams);
    },
     // 建立长连接
    rtcConnect(loginParams) {
        let {uid, appId} = loginParams;
        const uri = new duerRtcSdk.CallUri(uid, appId);
        loginParams.uri = uri;
        duerRtc.login(loginParams);
    },
    // 收到来电
    incomingViewShow() {
      this.$refs["incoming-call-dialog"].style.display = "block";
      document.getElementById("ring-music").play();
    },
    answerCall(mode) {
      duerRtc.answerCall(callInfo.index, mode, false);
      callInfo.callMode = mode;
    },
    // 拒接来电
    rejectCall() {
      duerRtc.rejectIncomingCall(callInfo.index, "REJECT_CALL_USER_REASON");
    },
    // 空闲状态
    freeCallView() {
      this.$refs["incoming-call-dialog"].style.display = "none";
      document.getElementById("ring-music").pause();
      document.getElementById("outing-call-view").style.display = "none";
      document.getElementById("call-view").style.display = "none";
      Object.assign(callInfo, {
        mute: false,
      });
    },
    // 通话正常接听
    callViewShow() {
        this.$refs["incoming-call-dialog"].style.display = "none";
        document.getElementById("outing-call-view").style.display = "none";
        document.getElementById("ring-music").pause();
        let muteIcon = document.getElementById("mute-icon");
        let muteText = document.getElementById("mute-text");
        let modeChangeIcon = document.getElementById("mode-change-icon");
        let modeChangeText = document.getElementById("mode-change-text");
        if (callInfo.mute) {
            // 已静音
            muteIcon.setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661515279209/quite.png');
            muteText.innerHTML = '已静音';
        } else {
            muteIcon.setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661515279806/quite0.png');
            muteText.innerHTML = '静音';
        }
        if (callInfo.callMode == duerRtcSdk.CallMode.AUDIO_VIDEO) {
            // 当前为视频通话
            modeChangeIcon.setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661515364706/audio000.png');
            modeChangeText.innerHTML = '语音通话'
            document.querySelector(".audio-tip-wrap").style.display = "none";
        } else if (callInfo.callMode == duerRtcSdk.CallMode.AUDIO_ONLY) {
            // 当前为音频通话
            modeChangeIcon.setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661828922567/videoCall0.png');
            modeChangeText.innerHTML = '视频通话'
            document.querySelector(".audio-tip-wrap").style.display = "block";
        }
        document.getElementById("call-view").style.display = "block";
    },
    // 设置音视频
    setLayouts(layouts) {
      if (!layouts) {
        return;
      }
      const audioLocal = document.getElementById('audio-local');
      const videoLocal = document.getElementById('video-local');
      const audioPeer = document.getElementById('audio-peer');
      const videoPeer = document.getElementById('video-peer');
      const localTip = document.querySelector('.local-view .audio-tip-wrap');
      const peerTip = document.querySelector('.peer-view .audio-tip-wrap');
      const [layoutLocal, layoutPeer] = layouts;
      if (layoutLocal.videoTrack) {
          videoLocal.style.display = 'block';
          localTip.style.display = 'none';
          const stream = new MediaStream;
          stream.addTrack(layoutLocal.videoTrack);
          videoLocal.srcObject = stream;
      } else {
          videoLocal.style.display = 'none';
          localTip.style.display = 'block';
      }
      if (layoutLocal.audioTrack && !layoutLocal.isLocal) {
        const stream = new MediaStream;
        stream.addTrack(layoutLocal.audioTrack);
        audioLocal.srcObject = stream;
      }
      if (layoutPeer.videoTrack) {
        videoPeer.style.display = 'block';
        peerTip.style.display = 'none';
        const stream = new MediaStream;
        stream.addTrack(layoutPeer.videoTrack);
        videoPeer.srcObject = stream;
      } else {
        videoPeer.style.display = 'none';
        peerTip.style.display = 'block';
      }
      if (layoutPeer.audioTrack) {
        const stream = new MediaStream;
        stream.addTrack(layoutPeer.audioTrack);
        audioPeer.srcObject = stream;
      }
    },
    // 静音状态切换
    changeMute() {
        let muteIcon = document.getElementById("mute-icon");
        let muteText = document.getElementById("mute-text");
       
        if (callInfo.mute) {
            // 已静音
            muteIcon.setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661515279209/quite.png');
            muteText.innerHTML = '已静音';
        } else {
            muteIcon.setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661515279806/quite0.png');
            muteText.innerHTML = '静音';
        }
        callInfo.mute = !callInfo.mute;
        duerRtc.muteAudio(callInfo.mute);
    },
    // 挂断通话
    dropCall() {
      duerRtc.dropCall('DROP_CALL_USER_REASON');
    },
    // 呼叫超时
    callTimeout() {
      document.getElementById("outing-call-view").style.display = "none";
       document.getElementById("message-alert").style.display = "block";
    },
    // 改变通话模式 type 1去电  2通话中
    changeMode() {
        const mode = callInfo.callMode == duerRtcSdk.CallMode.AUDIO_VIDEO
                             ? duerRtcSdk.CallMode.AUDIO_ONLY
                             : duerRtcSdk.CallMode.AUDIO_VIDEO;
        let modeChangeIcon = document.getElementById("mode-change-icon");
        let modeChangeText = document.getElementById("mode-change-text");
        if (mode == duerRtcSdk.CallMode.AUDIO_VIDEO) {
          modeChangeIcon.setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661515364706/audio000.png');
          modeChangeText.innerHTML = '语音通话';
          document.querySelector(".audio-tip-wrap").style.display = "none";
        } else if (mode == duerRtcSdk.CallMode.AUDIO_ONLY) {
          modeChangeIcon.setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661828922567/videoCall0.png');
          modeChangeText.innerHTML = '视频通话';
          document.querySelector(".audio-tip-wrap").style.display = "block";
        }
        duerRtc.changeCallMode(mode);
        callInfo.callMode = mode;
    },
     // 获取浏览器权限，根据实际情况调用，只限于用户没有做过授权
    getDevice() {
        navigator.getUserMedia = navigator.getUserMedia
                               || navigator.webkitGetUserMedia
                               || navigator.mozGetUserMedia
                               || navigator.msGetUserMedia;
        if (navigator.getUserMedia) {
            navigator.getUserMedia({video: true}, function onSuccess(stream) {
                console.log('摄像头开启成功');
            }, function onError(error) {
                alert("摄像头错误：", error);
            });
            navigator.getUserMedia({audio:true}, function onSuccess(stream) {
                console.log('麦克风开启成功');
            }, function onError(error) {
                alert("麦克风错误：", error);
            });
        }
        else {
            alert('暂不支持');
        }
    },
    // 去电响铃时界面展示
    ringViewShow() {
        document.getElementById("ring-music").play();
        document.querySelector('.peer-name').innerHTML = callInfo.name;
        if (callInfo.callMode == duerRtcSdk.CallMode.AUDIO_VIDEO) {
            // 当前为视频通话
            document.querySelector('.out-call-mode img').setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661515364706/audio000.png');
            document.querySelector('.out-call-mode-txt').innerHTML = '语音通话';
        } else if (callInfo.callMode == duerRtcSdk.CallMode.AUDIO_ONLY) {
            // 当前为音频通话
            document.querySelector('.out-call-mode img').setAttribute('src', 'https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661828922567/videoCall0.png');
            document.querySelector('.out-call-mode-txt').innerHTML = '视频通话';
        }
        document.getElementById("outing-call-view").style.display = "block";
        // $('#outing-call-view').show();
    },
    makeCall(appId, uid, callMode, name, callerRole = duerRtcSdk.CallerRole.CALL_CENTER) {
      const calleeUri = new duerRtcSdk.CallUri(uid, appId); 
      let intent = duerRtcSdk.MakeCallIntent(calleeUri, duerRtcSdk.CallMode.AUDIO_VIDEO);
      callMode && (intent.callMode = callMode);
      callerRole && (intent.callerRole = callerRole);
      callInfo.callMode = callMode;
      callInfo.name = name;
      const result = duerRtc.makeCall(intent)
      if (duerRtcSdk.CallReason.NORMAL == result) {
            // 呼出成功
        } else if (duerRtcSdk.CallReason.ALREADY_IN_CALL == result || CallReason.EXCEPTION_INPUT_ILLEGAL == result) {
            console.error('makeCall resaon', duerRtcSdk.CallReasonMap.get(result))
        }
        else {
            // 其他原因，目前不会出现
            console.warn('makeCall res', result)
        }
    },
    // 结束接听
    logout() {
      if (duerRtc) {
        duerRtc.logout();
      } else {
        this.$message.error('请先登录');
      }
    }
  },
  async created() {
    // this.getDevice();
  },
  async mounted() {
    // this.token = await this.getRtcToken();
    // setTimeout(async() => {
    //   await this.init();
    // }, 2000);
  },
};
</script>

<style scoped>
.duer-container {
  position: relative;
  z-index: 9999;
}
.incoming-come-call {
  display: none;
}
.modal-dialog {
  z-index: 999;
}
.usr-info {
  text-align: center;
}
.usr-pho {
  margin-top: 35px;
  width: 120px;
  height: 120px;
}
.usr-name {
  margin-top: 10px;
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  color: #1a1a1a;
}
.incoming-call-tip {
  font-size: 16px;
  line-height: 22px;
  color: #666666;
}
.op-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.op-btns img {
  width: 80px;
  height: 80px;
  margin: 16px 40px;
  cursor: pointer;
}
.op-txt {
  line-height: 20px;
  text-align: center;
}
#outing-call-view,
#call-view {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10052;
  background: #3c3667;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#outing-call-view .op-btns {
  margin-top: 50px;
}
.peer-pho {
  width: 104px;
  height: 104px;
  margin-top: 50px;
}
.peer-name {
  font-size: 60px;
  line-height: 86px;
}
.outing-call-tip {
  font-size: 42px;
  line-height: 60px;
  color: rgba(225, 225, 225, 0.5);
}
#call-view .op-btns {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.main-view {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-image: url("https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661773897681/audioBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  text-align: center;
}
.main-view video {
  width: 100%;
  object-fit: fill;
}
.main-view .audio-name {
  margin-top: 100px;
  font-size: 62px;
  line-height: 87px;
}
.main-view .audio-call {
  font-size: 36px;
  line-height: 50px;
  color: rgba(225, 225, 225, 0.5);
  margin-top: 17px;
}
.main-view .audio-tip-wrap {
  position: absolute;
  top: 0;
}
.sub-view {
  width: 320px;
  height: 192px;
  position: absolute;
  left: 48px;
  top: 48px;
  background-image: url("https://dumi-dueros-bj-tob.cdn.bcebos.com/amis/2022-8/1661773897681/audioBg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  color: #fff;
  z-index: 1;
}
.sub-view video {
  width: 320px;
  height: 192px;
  object-fit: fill;
}
.sub-view .audio-tip-wrap {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.sub-view .audio-name {
  font-size: 48px;
  line-height: 67px;
  max-width: 300px;
}
.sub-view .audio-call {
  font-size: 24px;
  line-height: 34px;
  text-align: right;
}
#message-alert {
  position: fixed;
  z-index: 1053;
  width: 300px;
  right: 0;
  top: 10px;
  display: none;
}
.desc-title {
  text-align: center;
  margin-bottom: 20px;
}
</style>
