<template>
  <div class="bed">
    <div class="bed_left">
      <div class="content">
        <div class="bulding">
          <div
              class="bulding-one"
              v-for="(name, index) in communityList"
              :class="[{ active: activeName == index }]"
              :key="index"
              @click="handleTabClick(index, name.id)"
          >
            <span style="font-size: 20px">{{ name.communityName }}</span>
            <div class="math-a" v-if="name.alarmCount > 0">
              <span>{{ name.alarmCount > 99 ? 99 : name.alarmCount}}</span>
            </div>
          </div>
        </div>
        <div class="bulding-message" style="">
          <div class="option">
            <div v-for="(item, index) in flatRoomList" :key="index" class="option-card" :class="[{'option-card-active': item.showAlarm === true}]">
                <div :class="[{'option-card_header_active': item.showAlarm === true}]" class="option-card_header">
                    <div>{{item.address}}</div>
                    <!-- <div>{{item.floorNo}}</div> -->
                </div>
              <div class="option-card_main">
                  <div class="option-card_main-left">
                    <div class="option-card_main-left-pic">
                      <div class="photo">
                        <img class="elderPic"  :src="item.pic" alt="">
                      </div>
                      <img class="sex" v-if="item.elderSex === 0" :src="boyzc" alt="">
                      <img class="sex" v-if="item.elderSex === 1" :src="girlzc" alt="">
                    </div>
                    <div class="option-card_main-left-name">
                        {{item.elderName}}&nbsp&nbsp&nbsp<span v-if="item.age">{{item.age}}岁</span>
                    </div>
                    <div class="option-card_main-left-level">
                      {{item.elderAbilityName}}
                    </div>
                    <div class="option-card_main-left-call-con">
                      <div class="call" @click="makeCall(item, '0')">
                        <img src="../assets/sp.png" alt="视频通话">
                      </div>
                      <div class="call" @click="makeCall(item, '1')">
                        <img src="../assets/yy.png" alt="语音通话">
                      </div>
                    </div>
                  </div>
                  <div class="option-card_main-right">
                    <div class="option-card_main-right-content">
                      <div class="icon cdsb"></div>
                      <div class="text">床垫设备：</div>
                      <div class="state">
                        <div v-if="item.connStatus === 1 && item.mattress === 1" style="background-color: #34BFA2;">在床</div>
                        <div v-if="item.connStatus === 1 && item.mattress === 0" style="background-color: #F49A2B;">离床</div>
                        <div v-if="item.connStatus === 0" style="background-color: #A0A0A0;">离线</div>
                        <div v-if="item.connStatus === null && item.mattress === null" style="background-color: #A0A0A0;">--</div>
                      </div>
                    </div>
                    <div class="option-card_main-right-content">
                      <div class="icon xl"></div>
                      <div class="text">心率数值：</div>
                      <div class="state">
                        <div v-if="item.HeartRate" style="background-color: #34BFA2;">{{item.HeartRate}}次/分</div>
                        <!-- <div v-if="item.HeartRate === 2" style="background-color: #F4526A;">异常</div>
                        <div v-if="item.HeartRate === 3" style="background-color: #A0A0A0;">离线</div> -->
                        <div v-if="item.HeartRate === null" style="background-color: #A0A0A0;">--</div>
                      </div>
                    </div>
                    <div class="option-card_main-right-content">
                      <div class="icon hx"></div>
                      <div class="text">呼吸数值：</div>
                      <div class="state">
                        <div v-if="item.respiration" style="background-color: #34BFA2;">{{item.respiration}}次/分</div>
                        <!-- <div v-if="item.respiration === 2" style="background-color: #F4526A;">异常</div>
                        <div v-if="item.respiration === 3" style="background-color: #A0A0A0;">离线</div> -->
                        <div v-if="item.respiration === null" style="background-color: #A0A0A0;">--</div>
                      </div>
                    </div>
                    <div class="option-card_main-right-content">
                      <div class="icon xl"></div>
                      <div class="text">跌倒设备：</div>
                      <div class="state">
                        <div v-if="item.FallDown === 0" style="background-color: #34BFA2;">工作中</div>
                        <div v-if="item.FallDown === 2" style="background-color: #F4526A;">报警中</div>
                        <div v-if="item.FallDown === 3" style="background-color: #A0A0A0;">离线</div>
                        <div v-if="item.FallDown === null" style="background-color: #A0A0A0;">--</div>
                      </div>
                    </div>
                    <div class="option-card_main-right-content">
                      <div class="icon hjbj"></div>
                      <div class="text">呼叫报警：</div>
                      <div class="state">
                        <div v-if="item.call === 0" style="background-color: #34BFA2;">工作中</div>
                        <div v-if="item.call === 2" style="background-color: #F4526A;">报警中</div>
                        <div v-if="item.call === 1" style="background-color: #A0A0A0;">设备异常</div>
                        <div v-if="item.call === null" style="background-color: #A0A0A0;">--</div>
                      </div>
                    </div>

                  </div>
              </div>
            </div>
            <!-- <div
              style="
                display: flex;
                margin-top: 10px;
                margin-left: 5px;
              "
              class="order-o"
              v-for="(room, index) in data"
              :key="index"
            > -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bed_right">
      <div class="bed_right_top">
        <div class="right-top-item">
          <div class="name">全部</div>
          <div class="num" style="color: #39c0ff">{{ staticData.callTotal }}</div>
        </div>
        <div class="right-top-item">
          <div class="name">已处理</div>
          <div class="num" style="color: #34bfa3">{{ staticData.dealedSum }}</div>
        </div>
         <div class="right-top-item">
          <div class="name">待处理</div>
          <div class="num" style="color: #f4516c">{{ staticData.waitDealSum }}</div>
        </div>
      </div>
      <div class="option">
        <!-- 缺省文字 -->
        <div class="no-data" v-if="alarmList.length === 0">
          <div class="no-data-img"></div>
          <div class="no-data-text">暂无报警信息</div>
        </div>
        <!-- 改版右侧 -->
        <div class="right-item"  v-for="(item, index) in alarmList" :key="index" v-else>
          <div class="item-top">
            <div class="customer-name">{{ item.customerName }}</div>
            <div class="call-name">{{ item.callTypeName }}</div>
            <div class="call-time">{{ item.lastCallTime?  item.lastCallTime.substring(5, 16): '-'}}</div>
          </div>
          <div class="info-con">
            <div class="addr">{{ item.address }}</div>
            <div class="status" style="color: #34bfa3" v-if="item.status === 2">[{{ item.statusName }}]</div>
            <div class="status" style="color: #39c0ff" v-if="item.status === 1">[{{ item.statusName }}]</div>
            <div class="status" style="color: #f4516c" v-if="item.status === 0">[{{ item.statusName }}]</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-carousel">
      <div class="icon"></div>
      <!-- <el-carousel class="lunbo" height="35" direction="vertical"  indicator-position="none">
        <el-carousel-item v-for="(item, index) in currentTabList" :key="index">
          <div class="carousel-title">{{ item.bedNo }}{{item.roomNo}} （告警<span style="color: #f4516c">{{item.count}}</span>次）</div>
        </el-carousel-item>
      </el-carousel> -->
      <notice :data="currentTabList"></notice>
    </div>
    <div class="alarmDialog" v-if="alarmDialog">
      <div class="title">!&nbsp;{{alarmContent.callTypeName}}</div>
      <a
        @click="playAudio()"
        class="tips-play"
        style="cursor: pointer; float: right; margin-right: 10px; color: #fff; font-size: 20px;"
        title="首次播报请手动点击播放"
        ><i class="el-icon-microphone"></i>
      </a>
      <div class="conten">
       {{alarmContent.content}}
      </div>
      <div class="btn-box">
        <div class="btn" @click="closeDialog()">知道了</div>
        <div class="close" @click="closeDotHandle()">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import notice from "@/components/Notice.vue";
import { getCall, getBedListByCommunityId, getRightEmergencyList, getStaticData , getBedListByStreetCommunityId, getTodayAttention } from "@/api/branch";
import { downloadUrl } from "@/utils/config";
import { getCallAppId } from "@/api/duer";
export default {
  name: "bed",
  components: {
    notice,
  },
  props: {
    communityList: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    communityList: {
      handler: function (val, oldVal) {
        if (val.length === 0) {
          return;
        }
        this.activeName = 0;
        this.communityList = val;
        if (val[0].communityName === '全部') {
          this.getAllBed();
        } else {
          this.getBedList();
        }
        this.getRightAlarmList();
      },
      deep: true,
    },
  },
  data() {
    return {
      downloadUrl,
      girlzc: require("@/assets/nv.png"),
      boyzc: require("@/assets/nan.png"),
      elderzc: require("@/assets/elder-pic.png"),
      cdsb: require("@/assets/cdsb.png"),
      radarTotalInfo: {
        total: '100',
        online: '98',
        offline: '2',
      },
      bedMonitorInfo: {
        total: '50',
        online: '50',
        offline: '0',
      },
      callAlarmInfo: {
        total: '100',
        online: '98',
        offline: '2',
      },
      list: [],
      flatRoomList: [],
      alarmList: [],
      activeName: 0,
      alarmDialog: false,
      alarmsetInterval: null,
      alarmContent: {},
      streetId: '',
      currentTabList: [],
      staticData: {}, // 统计数据
    }
  },
  mounted() {
    // this.getBedList();
    // this.getRightAlarmList();
    this.getTodayAttention();
  },
  methods: {
    getTodayAttention() {
      getTodayAttention({
        branchId: this.$branchId,
        lastCallTime: moment().format('YYYY-MM-DD'),
        minCount: 1,
      }).then(res => {
        this.currentTabList = res.map(item => {
          return `${ item.bedNo }${item.roomNo} （告警<span style="color: #f4516c">${item.count}</span>次）`
        });
      })
    },
    // 根据状态统计数量
    countByState(state) {
      let count = 0;
      this.alarmList.forEach(item => {
        if (item.status === state) {
          count++;
        }
      });
      return count;
    },
    closeDotHandle() {
      this.alarmDialog = false;
      this.getRightAlarmList();
    },
    // 处理报警
    handleAlarm(id) {
      getCall({
        id: id
      }).then(res => {
        console.log(res, '处理报警')
        this.getRightAlarmList();
      })
    },
    // 获取紧急处理列表
    getRightAlarmList() {
      console.log('右侧', this.communityList[this.activeName])
      if (this.communityList.length === 0) {
        return;
      }
      getRightEmergencyList({
        page: 1,
        rows: 100,
        branchId: this.$branchId,
        streetId: this.activeName === 0? Number(this.communityList[this.activeName].id): this.streetId,
        streetCommunityId: this.activeName === 0? '' : Number(this.communityList[this.activeName].id)
      }).then(res => {
        console.log(res, '获取紧急处理列表')
        this.alarmList = res.rows;
      })
      getStaticData({
        branchId: this.$branchId,
        streetIds: this.activeName === 0? Number(this.communityList[this.activeName].id): this.streetId,
        streetCommunityIds: this.activeName === 0? '' : Number(this.communityList[this.activeName].id)
      }).then(res => {
        console.log(res, '获取统计数据')
        this.staticData = res;
      })
    },
    // 获取床位列表
    getBedList() {
      if (this.communityList.length === 0) {
        return;
      }
      getBedListByCommunityId({
        page: 1,
        rows: 999,
        streetCommunityId: this.communityList[this.activeName].id,
        branchId: this.$branchId
      }).then(res => {
        this.flatRoomList = res.rows;
        this.flatRoomList.forEach((item, index) => {
          if (!item.headImageId || item.headImageId === null) {
            this.$set(this.flatRoomList[index], 'pic', require("@/assets/default_headimg.png"));
          } else {
            this.$set(this.flatRoomList[index], 'pic', `${this.downloadUrl}?id=${item.headImageId}`);
          }
          item.iotList.forEach((item_, index_) => {
            if (item_.type === 5) {  // 床垫
              this.$set(this.flatRoomList[index], 'connStatus', item_.connStatus);
              if (item_.connStatus === 1) {  // 判断设备是否在线
                this.$set(this.flatRoomList[index], 'mattress', item_.onBedStatus);
              } else {
                this.$set(this.flatRoomList[index], 'mattress', null);
              }
              // this.$set(this.flatRoomList[index], 'mattress', item_.connStatus);
              this.$set(this.flatRoomList[index], 'HeartRate', item_.heart)
              this.$set(this.flatRoomList[index], 'respiration', item_.breath)
            }
            if (item_.type === 1) { // 跌倒
              this.$set(this.flatRoomList[index], 'FallDown', item_.workStatus)
            }
            if (item_.type === 0) { // 呼叫
              this.$set(this.flatRoomList[index], 'call', item_.workStatus)
            }
          })
          const findIndex = item.iotList.findIndex(item => {
            return item.workStatus === 2
          })
          if (findIndex !== -1) {
            this.$set(this.flatRoomList[index], 'showAlarm', true)
          } else {
            this.$set(this.flatRoomList[index], 'showAlarm', false)
          }
        })
        console.log(this.flatRoomList, '床位列表.flatRoomList');
      })
    },
    // 获取所有床位
    getAllBed() {
      getBedListByStreetCommunityId({
          page: 1,
          rows: 999,
          streetId: this.communityList[this.activeName].id,
          branchId: this.$branchId
        }).then(res => {
          this.flatRoomList = res.rows;
          this.flatRoomList.forEach((item, index) => {
            if (!item.headImageId || item.headImageId === null) {
              this.$set(this.flatRoomList[index], 'pic', require("@/assets/default_headimg.png"));
            } else {
              this.$set(this.flatRoomList[index], 'pic', `${this.downloadUrl}?id=${item.headImageId}`);
            }
            item.iotList.forEach((item_, index_) => {
                if (item_.type === 5) {  // 床垫
                  this.$set(this.flatRoomList[index], 'connStatus', item_.connStatus);
                if (item_.connStatus === 1) {  // 判断设备是否在线
                  this.$set(this.flatRoomList[index], 'mattress', item_.onBedStatus);
                } else {
                  this.$set(this.flatRoomList[index], 'mattress', null);
                }
                this.$set(this.flatRoomList[index], 'HeartRate', item_.heart)
                this.$set(this.flatRoomList[index], 'respiration', item_.breath)
              }
              if (item_.type === 1) { // 跌倒
                this.$set(this.flatRoomList[index], 'FallDown', item_.workStatus)
              }
              if (item_.type === 0) { // 呼叫
                this.$set(this.flatRoomList[index], 'call', item_.workStatus)
              }
            })
            const findIndex = item.iotList.findIndex(item => {
              return item.workStatus === 2
            })
            if (findIndex !== -1) {
              this.$set(this.flatRoomList[index], 'showAlarm', true)
            } else {
              this.$set(this.flatRoomList[index], 'showAlarm', false)
            }
          })
        })
    },
    reFresh(data) {
      this.flatRoomList.forEach((item, index) => {
        if (item.id === data.bedId) {
          this.flatRoomList[index].iotList.forEach((item_, index_) => {
            if(item_.deviceSn === data.deviceSn) {
              this.$set(this.flatRoomList[index], 'connStatus', item_.connStatus);
              if (data.connStatus === 1) {  // 判断设备是否在线
                this.$set(this.flatRoomList[index], 'mattress', data.onBedStatus);
              } else {
                this.$set(this.flatRoomList[index], 'mattress', null);
              }
              this.$set(this.flatRoomList[index], 'HeartRate', data.heart)
              this.$set(this.flatRoomList[index], 'respiration', data.breath)
            }
          })
        }
      })
    },
    handleTabClick(tab, communityId) {
      this.activeName = tab;
      if (tab === 0 ) {
        this.streetId = ''
        this.getAllBed();
      } else {
        this.streetId = this.communityList[this.activeName].streetCommunityId;
        this.getBedList();
      }
      this.getRightAlarmList();
    },
    openDialog(data) {
      console.log(data, '-----------------------------');
      if (data.params.callId) {
        this.alarmDialog = true;
        this.alarmContent = data.params;
      }
    },
    closeDialog() {
      getCall({ id: this.alarmContent.callId }).then((res) => {
        this.alarmDialog = false;
        this.getRightAlarmList();
      });
    },
    makeCall(item, type) {
      console.log(item, 'item, type');
      const iotSn = item.iotList.find(item_ => item_.type === 85).deviceSn;
      if (iotSn) {
       getCallAppId(iotSn).then(res => {
          const res_ = JSON.parse(JSON.stringify(res));
          console.log(res_, 'res_');
          this.$emit("call", res_.callAppid, res_.callAddress, item.elderName, type);
        })
      } else {
        this.$message.error('该床位没有绑定设备');
      }
    },
    playAudio() {
      this.$emit('play');
    },
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
.bed{
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  flex-wrap: wrap;
  &_left{
    flex: 23;
    max-width: 1500px;
    min-width: 1200px;
    // height: calc(100vh - 200px);
    overflow-y: auto;
    .status {
      display: flex;
      justify-content: space-between;
      .status-leida {
        width: 32%;
        height: 150px;
        background-image: url("~@/assets/ldddbg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        .img{
          display: flex;
          flex: 2;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
        }
        .working {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          margin-top: 7px;
          font-size: 14px;
        }
        .noworking {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          margin-top: 7px;
          margin-left: 15px;
          font-size: 14px;
        }
      }
      .status-chuangtian {
        width: 32%;
        height: 150px;
        background-image: url("~@/assets/zncdbg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        // align-items: center;
        .img{
          display: flex;
          flex: 2;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
        }
        .working {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          margin-top: 7px;
          font-size: 14px;
        }
        .noworking {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          margin-top: 7px;
          margin-left: 15px;
          font-size: 14px;
        }
      }
      .status-baojing {
        width: 33%;
        height: 150px;
        background-image: url("~@/assets/hjbjbg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        // align-items: center;
        .img{
          display: flex;
          flex: 2;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
        }
        .working {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          margin-top: 7px;
          font-size: 14px;
        }
        .noworking {
          width: 10px;
          height: 10px;
          font-size: 14px;
          border-radius: 50%;
          background-color: #fff;
          margin-top: 7px;
          margin-left: 15px;
        }
      }
    }
    .content {
      // width: 1841px;
      // height: 716px;
      // background-image: url("~@/assets/kuang2.png");
      margin: 20px auto 0;
      // position: relative;
      .bulding {
        position: sticky;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        z-index: 100;
        .bulding-one {
          max-width: 140px;
          min-width: 80px;
          height: 50px;
          cursor: pointer;
          background-color: #37709D;
          // border-radius: 20px  0 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 5px;
          margin-left: 5px;
          color: #8CCDD5;
          font-weight: bold;
        }
        .math-a{
          background-color: #E56C20;
          border: 1px #fff solid;
          border-radius: 100%;
          color: #fff;
          font-size: 14px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          margin-left: 10px;
        }
        .active {
          background-color: #8dcdd6 !important;
          color: #fff !important;
        }
      }
      .bulding .bulding-one:first-child {
        max-width: 140px;
        min-width: 80px;
        height: 50px;
        cursor: pointer;
        background-color: #37709D;
        border-radius: 20px 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-left: 5px;
        color: #8CCDD5;
        font-weight: bold;
      }
      .bulding .bulding-one:last-child {
        max-width: 140px;
        min-width: 80px;
        height: 50px;
        cursor: pointer;
        background-color: #37709D;
        border-radius: 0 20px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-left: 5px;
        color: #8CCDD5;
        font-weight: bold;
      }

      .option{
        box-sizing: border-box;
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        height: calc(100vh - 300px);
        align-items: flex-start;
        justify-content: flex-start;
        // overflow-y: scroll;
        overflow-y: overlay;
        align-content: flex-start;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        .option-card-active {
          background-color: rgba(244, 81, 108, 0.4) !important;
          border: 2px solid rgba(244, 81, 108, 1) !important;
        }
        .option-card{
          background-color: rgba(16,58,108, .7);
          min-width: 330px;
          width: 24%;
          max-height: 250px;
          border-radius: 4px;
          margin-right: 8px;
          margin-bottom: 8px;
          border: 2px solid transparent;
          &_header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 10px;
            color: #fff;
            font-size: 14px;
            border-bottom: 2px solid #0C2C5C;
          }
          &_header_active{
            background-color: rgba(244, 81, 108, 1);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 10px;
            color: #fff;
            font-size: 14px;
            border-bottom: 2px solid rgba(244, 81, 108, 1);
           
          }
          &_main{
            display: flex;
            padding: 20px;

            &-left{
              flex: 4;
              &-pic{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .photo{
                  width: 80px;
                  height: 80px;
                  border-radius: 100%;
                  overflow: hidden;


                  .elderPic{
                    width: 80px;
                    height: 80px;
                  }

                }
                .sex{
                  position: absolute;
                  bottom: 5px;
                  right: 25px;
                }
              }
              &-name{
                color: #fff;
                text-align: center;
                padding-top: 5px;
                font-size: 14px;
              }
              &-level{
                text-align: center;
                padding-top: 5px;
                color: #35BFA4;
                font-size: 14px;
              }
              &-call-con {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 40px;
                  margin: 5px 1px 0px;
                  cursor: pointer;
                }
              }
            }
            &-right{
              flex: 6;
              &-content{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .icon{
                  width: 15px;
                  height: 15px;
                }
                .cdsb{
                  background: url("~@/assets/cdsb.png") no-repeat;
                  background-size: 100% 100%;
                }
                .xl{
                  background: url("~@/assets/xl.png") no-repeat;
                  background-size: 100% 100%;
                }
                .hx{
                  background: url("~@/assets/hx.png") no-repeat;
                  background-size: 100% 100%;
                }
                .ddsb{
                  background: url("~@/assets/ddsb.png") no-repeat;
                  background-size: 100% 100%;
                }
                .hjbj{
                  background: url("~@/assets/hjbj.png") no-repeat;
                  background-size: 100% 100%;
                }
                .state{
                  div{
                    padding: 0 5px;
                    color: #fff;
                    font-size: 14px;
                  }
                }
                .text{
                  color: #fff;
                  margin-left: 10px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

    }
  }
  &_right{
    flex: 7;
    padding-left: 30px;
    height: calc(100vh - 200px);
    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right-top-item {
        width: 107px;
        height: 107px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: url("~@/assets/num-bg.png") no-repeat center bottom;
        background-size: contain;
        .name {
          color: #fff;
          font-size: 20px;
        }
        .num {
          color: #fff;
          font-size: 22px;
          margin-top: 10px;
        }
      }
    }
    &-title{
      font-size: 30px;
      color: #fff;
    }

    .option{
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      max-height: calc(100vh - 280px);
      overflow-y: scroll;
      .option-card{
        background-color: rgba(16,58,108, .7);
        width: 100%;
        border-radius: 4px;
        margin-bottom: 10px;
        min-width: 330px;
        &_header{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 10px;
          color: #fff;
          font-size: 14px;
          border-bottom: 2px solid #0C2C5C;

          &-alarmtype{
            color: #F4516C;
            font-size: 18px;
            text-align: center;
          }
          &-alarmtime{
            color: #A0A0A0;
          }
        }
        &_main{
          display: flex;
          padding: 12px 20px;

          &-left{
            flex: 4;
            &-pic{
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              .photo{
                width: 80px;
                height: 80px;
                border-radius: 100%;
                overflow: hidden;


                .elderPic{
                  width: 80px;
                  height: 80px;
                }

              }
              .sex{
                position: absolute;
                bottom: 5px;
                right: 40px;
              }
            }
            &-name{
              color: #fff;
              text-align: center;
              padding-top: 5px;
              font-size: 14px;
            }
            &-level{
              text-align: center;
              padding-top: 5px;
              color: #35BFA4;
              font-size: 14px;
            }
          }
          &-right{
            flex: 6;
            &-content{
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              .icon{
                width: 15px;
                height: 15px;
              }
              .cdsb{
                background: url("~@/assets/cdsb.png") no-repeat;
                background-size: 100% 100%;
              }
              .xl{
                background: url("~@/assets/xl.png") no-repeat;
                background-size: 100% 100%;
              }
              .hx{
                background: url("~@/assets/hx.png") no-repeat;
                background-size: 100% 100%;
              }
              .ddsb{
                background: url("~@/assets/ddsb.png") no-repeat;
                background-size: 100% 100%;
              }
              .hjbj{
                background: url("~@/assets/hjbj.png") no-repeat;
                background-size: 100% 100%;
              }
              .state{
                div{
                  padding: 0 5px;
                  color: #fff;
                  font-size: 14px;
                }
              }
              .text{
                color: #fff;
                margin-left: 10px;
                font-size: 14px;
              }
            }
          }
        }
        &_btn{
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction:column;
          background-color: #39C1FF;
          border-radius: 0 0 4px 4px;
          div{
            text-align: center;
            color: #fff;
          }
        }
      }
      .option-card:last-child{
        margin-bottom: 0px;
      }
      .right-item:first-child {
        border-top: 1px solid #39c0ff;
      }
      .right-item {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        min-width: 330px;
        border-bottom: 1px solid #39c0ff;
        color: #fff;
        padding: 15px 0;
        .item-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .customer-name {
            font-size: 18px;
            color: #fff;
          }
          .call-name {
            font-size: 14px;
            color: #39c0ff;
          }
          .call-time {
            font-size: 14px;
          }
        }
        .info-con {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-top: 10px;
          .addr {
            max-width: 70%;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
  }

  .alarmDialog{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 540px;
    min-height: 260px;
    padding: 30px;
    background: #030F27;

    animation: animated-border .6s infinite;

    .title{
      text-align: center;
      color: #F4516C;
      font-size: 28px;
      margin-bottom: 35px;
    }

    .conten{
      color: #fff;
      text-align: center;
      font-size: 20px;
      margin-bottom: 10px;
    }
    .btn-box{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .btn{
        padding: 5px 55px;
        font-size: 18px;
        background: #F4516C;
        color: #fff;
        border-radius: 2px;
        margin-top: 40px;
        cursor: pointer;
      }
      .close {
        padding: 5px 55px;
        font-size: 18px;
        background: #A0A0A0;
        color: #fff;
        border-radius: 2px;
        margin-top: 10px;
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}
.bottom-carousel {
  position: relative;
  bottom: -10px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px;
  padding-bottom: 15px;
  .icon {
    width: 100px;
    height: 40px;
    background: url("~@/assets/day-icon.png") no-repeat center;
    background-size: contain;
    margin-right: 20px;
  }
  .lunbo {
    padding: 0 8px 0 0;
    box-sizing: border-box;
    height: 35px;//文本区域的高度
    line-height: 35px;//文本的行间距，与高度相同，则表示一行展示
    text-overflow: ellipsis;//超出内容...展示
    word-break: keep-all;//是否折断文本，keep-all表示不折断
    white-space: nowrap;//不换行展示文本
    overflow: hidden;//超出部分隐藏，与text-overflow配合使用
    flex: 1;//这个是跟左侧的图片组成的一个flex布局
    .carousel-title {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }
}

@keyframes animated-border {
  0% {
    box-shadow: inset 0px 0px 40px 0px #AB122F;
  }

  40% {
    box-shadow: inset 0px 0px 30px 0px #AB122F;
  }

  80% {
    box-shadow: inset 0px 0px 25px 0px #AB122F;
  }


  100% {
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0);
  }
}
.option-card_btn {
  cursor: pointer;
}
.tips-play {
  position: absolute;
  top: 20px;
  right: 10px;
}
.no-data {
  color: rgba(255,255,255, .5);
  width: 100%;
  height: 500px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
