<template>
  <div style="height: 100vh">
    <ScreenAdapter>
      <div class="cockpit">
        <div class="header">
          <!--      <div class="header-name" style="margin-top: 20px;visibility:hidden;">-->
          <!--        <select-->
          <!--          name=""-->
          <!--          id=""-->
          <!--          class="select-upp-a"-->
          <!--          v-model="branch"-->
          <!--          @change="setbranch($event)"-->
          <!--          disabled-->
          <!--        >-->
          <!--          <option-->
          <!--            v-for="(item, index) in branchList"-->
          <!--            :key="index"-->
          <!--            :value="item.id"-->
          <!--            :label="item.name"-->
          <!--          ></option>-->
          <!--        </select>-->
          <!--      </div>-->
          <div class="header-img" style="display: flex;align-items: center;justify-content: center;position: relative">
            <img
                :src="headerlogo"
                alt=""
            />
            <div class="header-left" style="position: absolute; top: 20px; left: 31px;display: flex;">
              <div class="item">
                <img :src="qiwen" alt="">
                <div>气温 <span>{{ weather.temperature? weather.temperature : '-' }}℃</span></div>
              </div>
              <div class="item">
                <img :src="tianqi" alt="">
                <div>天气 <span>{{ weather.weather? weather.weather : '-' }}</span></div>
              </div>
            </div>
            <div class="header-right" style="position: absolute; top: 35px; right: 31px;display: flex;">
              <div style="color: #00B0FB; font-size: 22px">{{timeMode()}}{{time}}</div>
            </div>
          </div>
          <div class="header-message" >

          </div>
        </div>
        <div class="option">
          <div class="option-card">
            <div class="option-card_title">
              <img :src="verticalLine" alt="">
              <div class="title">床位总数</div>
            </div>
            <div class="option-card_num">
<!--              <div class="num">{{statistics.bedCount ? statistics.bedCount : 0}}</div>-->
              <div class="num">319</div>
              <div class="unit">床</div>
            </div>
          </div>
          <div class="option-card">
            <div class="option-card_title">
              <img :src="verticalLine" alt="">
              <div class="title">建设中床位数</div>
            </div>
            <div class="option-card_num">
              <div class="num">{{statistics.buildingBeds ? statistics.buildingBeds : 0}}</div>
              <div class="unit">床</div>
            </div>
          </div>
          <div class="option-card">
            <div class="option-card_title">
              <img :src="verticalLine" alt="">
              <div class="title">运营中床位数</div>
            </div>
            <div class="option-card_num">
<!--              <div class="num">{{statistics.haveHumanBeds ? statistics.haveHumanBeds : 0}}</div>-->
              <div class="num">319</div>
              <div class="unit">床</div>
            </div>
          </div>
          <div class="option-card">
            <div class="option-card_title">
              <img :src="verticalLine" alt="">
              <div class="title">服务总人数</div>
            </div>
            <div class="option-card_num">
              <div class="num">{{statistics.nurseNum ? statistics.nurseNum : 0}}</div>
              <div class="unit">人</div>
            </div>
          </div>
          <div class="option-card">
            <div class="option-card_title">
              <img :src="verticalLine" alt="">
              <div class="title">改造总户数</div>
            </div>
            <div class="option-card_num">
              <div class="num">319</div>
              <!-- <div class="num">{{statistics.bedsModifiedCount ? statistics.bedsModifiedCount : 0}}</div> -->
              <div class="unit">户</div>
            </div>
          </div>
          <div class="option-card">
            <div class="option-card_title">
              <img :src="verticalLine" alt="">
              <div class="title">智慧设备绑定数</div>
            </div>
            <div class="option-card_num">
              <div class="num">{{statistics.bindIotCount ? statistics.bindIotCount : 0}}</div>
              <div class="unit">件</div>
            </div>
          </div>
          <div class="option-card">
            <div class="option-card_title">
              <img :src="verticalLine" alt="">
              <div class="title">事件处理</div>
            </div>
            <div class="option-card_num">
              <div class="num">{{statistics.successRequestCount ? statistics.successRequestCount : 0}}</div>
              <div class="unit">件</div>
            </div>
          </div>
        </div>
        <div class="main">
          <div class="main-left">
            <div class="main-left_top">
              <div class="title">
                <div>长者概况</div>
                <img :src="jiantou" style="width: 16px;height: 11px" alt="">
              </div>
              <div class="main-left_top_mian">
                <div id="pie1" class="echart"></div>
                <div id="pie2" class="echart"></div>
                <div id="pie3" class="echart"></div>
              </div>
            </div>
            <div class="main-left_middle">
              <div class="title">
                <div>改造展示</div>
                <img :src="jiantou" style="width: 16px;height: 11px" alt="">
              </div>
              <div class="main-left_middle_mian">
                <!-- <div class="tab">
                  <div @click="tabChang2(1)" class="tab_opiton" :class="{'tab_opiton-action': tabIndex2 === 1}">
                    室内改造
                  </div>
                  <div @click="tabChang2(2)" class="tab_opiton" :class="{'tab_opiton-action': tabIndex2 === 2}">
                    厕所改造
                  </div>
                  <div @click="tabChang2(3)" class="tab_opiton" :class="{'tab_opiton-action': tabIndex2 === 3}">
                    辅具适配
                  </div>
                </div> -->
                <div class="pic">
                  <img :src="reform1" alt="">
                  <img :src="reform2" alt="">
                  <img :src="reform3" alt="">
                </div>
              </div>
            </div>
            <div class="main-left_bottom">
              <div class="title">
                <div>实时报警</div>
                <img :src="jiantou" style="width: 16px;height: 11px" alt="">
              </div>
              <div class="main-left_bottom_mian">
                <div class="main-right_bottom_mian-header">
                  <div style="flex: 2;">报警时间</div>
                  <div style="flex: 3;">报警类型</div>
                  <div style="flex: 3;">报警内容</div>
                  <div style="flex: 2;">处理情况</div>
                </div>
                <div class="alarm-con">
                  <div v-for="(item, index) in alarmList" :key="index+'a'" style="margin-bottom: 10px;" class="main-right_bottom_mian-opiton">
                    <div style="flex: 2;">{{getTime(item.lastCallTime)}}</div>
                    <div style="flex: 3;" :style="{color:item.callType === 1 ? '#E56C20' : '#F4516C'}">{{item.callTypeName}}</div>
                    <div style="flex: 3;">
                      <div class="ellipsis">{{item.content}}</div>
                    </div>
                    <div  style="flex: 2;" :style="{color:item.status !== 2 ? '#E56C20' : '#35BFA4'}">{{item.statusName}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-middle" id="map_container"></div>
          <div class="main-right">
            <div class="main-right_top">
              <div class="title">
                <div>服务排行</div>
                <img :src="jiantou" style="width: 16px;height: 11px" alt="">
                 <div style="font-size: 14px;margin-bottom: -6px;margin-left: 20%;">
<!--                  <span style="color: #28A7F4">总计</span>-->
<!--                  <span style="margin-left: 10px">年</span>-->
<!--                  <span style="margin-left: 10px">月</span>-->
<!--                  <span style="margin-left: 10px">周</span>-->
<!--                  <span style="margin-left: 10px">日</span>-->
                   <el-date-picker
                       style="width: 120px"
                       class="date"
                       :clearable="false"
                       size="mini"
                       v-model="date"
                       @change="getListItem"
                       value-format="yyyy-MM"
                       format="yyyy-MM"
                       type="month"
                       placeholder="选择月">
                   </el-date-picker>
                </div>
              </div>
              <div class="main-right_top_mian">
                <div class="tab">
                  <div @click="tabChang(1)" class="tab_opiton" :class="{'tab_opiton-action': tabIndex1 === 1}">
                     服务项目
                  </div>
                  <div @click="tabChang(2)" class="tab_opiton" :class="{'tab_opiton-action': tabIndex1 === 2}">
                    服务人次数
                  </div>
                  <div @click="tabChang(3)" class="tab_opiton" :class="{'tab_opiton-action': tabIndex1 === 3}">
                    按乡镇
                  </div>
                </div>
                <div class="content scrollbar">
                  <el-row :gutter="10" v-for="(item, index) in list1" :key="index" style="margin-bottom: 10px;">
                    <el-col :md="6" :lg="6" :xl="6" :span="6"><div style="text-align: right" class="ellipsis">{{item.name}}</div></el-col>
                    <el-col :md="11" :lg="11" :xl="14" :span="14">
                      <div class="box">
                        <!--                      <div class="line" :style="{width: ((Number(item.itemQuantity) / Number(this.total1)) * 100 )} + '%'"></div>-->
                        <div class="line" :style="{width: getRate(item.num)}"></div>
                      </div>
                    </el-col>
                    <el-col :md="6" :lg="4" :xl="4" :span="4"><div style="vertical-align: center;">{{item.num}}人次</div></el-col>
                  </el-row>
                </div>
                <div class="headcount">
                  <el-row :gutter="10" >
                    <el-col :md="6" :lg="6" :xl="6" :span="6"><div style="text-align: right; color: #fff" >总人次：</div></el-col>
                    <el-col :md="11" :lg="11" :xl="14" :span="14" style="color:#fff;">
                      {{total1}}
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="main-right_bottom">
              <div class="title">
                <div>服务工单</div>
                <img :src="jiantou" style="width: 16px;height: 11px" alt="">
              </div>
              <div class="main-right_bottom_mian">
                <div class="main-right_bottom_mian-header">
                  <!-- <div style="flex: 2;">工单类型</div> -->
                  <div style="flex: 2;">预约时间</div>
                  <div style="flex: 6;">服务类型</div>
                </div>
                <div class="alarm-con">
                  <div v-for="(item, index) in orderList" :key="index+'a'" style="margin-bottom: 10px;" class="main-right_bottom_mian-opiton">
                    <!-- <div style="flex: 2; text-align: left">{{item.orderServeTypeName}}</div> -->
                    <div style="flex: 2;">{{getDate(item.startTim)}}</div>
                    <div style="flex: 6;">
                      <div class="ellipsis">{{item.orderServeHuman + ' '}}{{item.orderServeItemName}}&nbsp;&nbsp;地址:{{item.orderServeAddress}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScreenAdapter>
  </div>

</template>

<script>
import moment from "moment";
import * as echarts from 'echarts';
import ScreenAdapter from "@/components/ScreenAdapter"; // 自适应
import {
  getAlarmList,
  getlistItemAll,
  getlistItemBySuccess,
  getServiceList,
  getYlcStatic,
  getHeatMapData,
  getPieData,
  getWeather,
  getlistItemGroupByTown, statisticsByUser
} from "@/api/branch";
import mapStyleJson from "@/utils/map-style-json.js";
// import mapData from "@/utils/data.js";
const mapvgl = require('mapvgl')
export default {
  name: "Cockpit",
  components: {
    ScreenAdapter
  },
  data() {
    return {
      mapStyleJson,
      mapData: [],
      // mapData,
      headerlogo: require("@/assets/header-logo.png"),
      qiwen: require("@/assets/qiwen.png"),
      tianqi: require("@/assets/tianqi.png"),
      verticalLine: require("@/assets/vertical-line.png"),
      jiantou: require("@/assets/jiantou.png"),
      reform1: require("@/assets/change1.jpg"),
      reform2: require("@/assets/change2.jpg"),
      reform3: require("@/assets/change3.jpg"),
      time: '',
      tabIndex1: 1,
      tabIndex2: 1,
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      total1: [],
      date: null,
      total2: [],
      total3: [],
      total4: [],
      orderList: [],
      alarmList: [],
      timesetInterval: null,
      listsetInterval: null,
      weatherInterval: null,
      statistics: {
        bedCount: 0,
        buildingBeds: 0,
        haveHumanBeds: 0,
        nurseNum: 0,
        bedsModifiedCount: 0,
        bindIotCount: 0,
        successRequestCount: 0,
      },
      search:{
        beginRequestTime: null,
        endRequestTime: null,
      },
      weather: {}
    }
  },
  async created() {
    // this.time = moment(new Date()).format('HH:mm:ss')
    // 设置页面title
    this.date = moment().format('YYYY-MM')
    this.search.beginRequestTime = moment(this.date).format('YYYY-MM-01')
    this.search.endRequestTime = moment(this.date).endOf("month").format('YYYY-MM-DD')
    document.title = '开化县家庭养老床综合监护管理驾驶舱'
    await this.init(1)
    this.weather = (await getWeather('开化县')).showapi_res_body.now
    console.log(this.weather, 'weather');
    this.timesetInterval = setInterval(this.formatDate, 500);
    this.weatherInterval = setInterval(async () => {
      this.weather = (await getWeather('开化县')).showapi_res_body.now
    }, 1000 * 60 * 60);
    // this.listsetInterval = setInterval(this.init, 10000);
    this.$nextTick(async () => {
    //  this.chart1()
    //  this.chart2()
    //  this.chart3()
    //  this.initMap()
    })
  },
  mounted() {

  },
  methods: {
    async init(num) {
     this.getAlarmList()
     this.getServiceList()
     this.getYlcStatic()
     this.getlistItemAll(num)
     this.getlistItemBySuccess()
     this.getlistItemGroupByTown()
     this.getHeatMapData()
     this.getChartData()
    },
    getListItem(val) {
      if (val) {
        this.search.beginRequestTime = moment(val).format('YYYY-MM-01')
        this.search.endRequestTime = moment(val).endOf("month").format('YYYY-MM-DD')
        if (this.tabIndex1 === 1) {
          this.getlistItemAll(1)
          this.getlistItemBySuccess()
          this.getlistItemGroupByTown()
        } else if (this.tabIndex1 === 2) {
          this.getlistItemAll(2)
          this.getlistItemBySuccess()
          this.getlistItemGroupByTown()
        } else if (this.tabIndex1 === 3) {
          this.getlistItemAll(3)
          this.getlistItemBySuccess()
          this.getlistItemGroupByTown()
        }
      } else {
        this.search.beginRequestTime = null
        this.search.endRequestTime = null
        if (this.tabIndex1 === 1) {
          this.getlistItemAll(1)
        } else if (this.tabIndex1 === 2) {
          this.getlistItemBySuccess()
        } else if (this.tabIndex1 === 3) {
          this.getlistItemGroupByTown()
        }
      }
    },
    getChartData() {
      getPieData({
        branchId: this.$branchId
      }).then(res => {
        console.log(res, 'res');
        this.$nextTick(() => {
          this.chart1(res.age);
          this.chart2(res.sexNumber);
          this.chart3(res.abality);
        })
      })
    },
    getHeatMapData() {
      getHeatMapData({
        branchId: this.$branchId
      }).then(res => {
        this.mapData = res
        this.$nextTick(() => {
          this.initMap();
        })
      })
    },
    initMap() {
      var map = new BMapGL.Map("map_container");
      // map.centerAndZoom('浙江省衢州市开化县', 11);
      // map.centerAndZoom('北京市', 11);
      console.log(map);
      var point = new BMapGL.Point(118.418584,29.142178);
      map.centerAndZoom(point, 11);
      map.setMapStyleV2({ styleJson: mapStyleJson });
      map.enableScrollWheelZoom(true);
      var view = new mapvgl.View({
          map: map
      });
          let data = [];
          let rs = this.mapData;
          // let rs = mapData;
           for (var i = 0; i < rs.length; i++) {
            var item = rs[i];
            // if (item.lo && item.la) {
            //     console.log(item.lo, item.la);
            // }
            data.push({
                geometry: {
                    type: 'Point',
                    coordinates: [item.lo, item.la]
                },
                properties: {
                    count: 1
                }
                // geometry: {
                //     type: 'Point',
                //     coordinates: [item[0], item[1]]
                // },
                // properties: {
                //     count: 1
                // }
            });
        }
        var heatmap = new mapvgl.HeatmapLayer({
            size: 500, // 单个点绘制大小
            max: 40, // 最大阈值
            min: 0, // 最小阈值
            height: 0, // 最大高度，默认为0
            unit: 'px', // 单位，m:米，px: 像素
            gradient: { // 对应比例渐变色
                0.25: 'rgba(0, 0, 255, 1)',
                0.55: 'rgba(0, 255, 0, 1)',
                0.85: 'rgba(255, 255, 0, 1)',
                1: 'rgba(255, 0, 0, 1)'
            }
            // gradient: { // 对应比例渐变色
            //     0.25: 'rgba(89, 233, 179, 1)',
            //     0.55: 'rgba(182, 243, 147, 1)',
            //     0.85: 'rgba(254, 255, 140, 1)',
            //     0.9: 'rgba(217, 29, 28, 1)',
            // }
        });
        view.addLayer(heatmap);
        heatmap.setData(data);
    },
    // 获取报警列表
    getAlarmList() {
      getAlarmList({
        "page": 1,
        "branchId": this.$branchId,
        "rows":"4",
        }).then(res => {
        // console.log(res)
        this.alarmList = res.rows
      })
    },
    // 获取服务工单
    getServiceList() {
      getServiceList({
        "page": 1,
        "pcOrApp": 1,
        "beginRequestTime": "",
        "endRequestTime" :"",
        "rows":"10",
        "statusId":222}).then(res => {
        // console.log(res)
        this.orderList = res.rows
      })
    },
    // 获取数据统计
    getYlcStatic() {
      getYlcStatic({
        "branchId": this.$branchId,
      }).then(res => {
        this.statistics = res
      })
    },
    // 服务人次数
    getlistItemAll(num) {
      statisticsByUser({
        "branchId": this.$branchId,
        dataType: 'item',
        ...this.search
      }).then(res => {
        this.list2 = res.item
        this.total2 = res.totalNum
        this.$nextTick( async () => {
          if (num === 1) {
            setTimeout(() => {
              this.tabChang(1)
            }, 100)
          }
        })
      })
    },
    // 事件处理件数
    getlistItemBySuccess() {
      statisticsByUser({
        "branchId": this.$branchId,
        dataType: 'user',
        ...this.search,
      }).then(res => {
        this.list3 = res.user
        this.total3 = res.totalNum
        this.$nextTick( async () => {
          if (this.tabIndex1 === 2) {
            setTimeout(() => {
              this.tabChang(2)
            }, 100)
          }
        })
      })
    },
    // 按乡镇排行
    getlistItemGroupByTown() {
      statisticsByUser({
        "branchId": this.$branchId,
        dataType: 'town',
        ...this.search
      }).then(res => {
        let arr = res.town
        this.list4 = arr
        this.total4 = res.totalNum
        this.$nextTick( async () => {
          if (this.tabIndex1 === 3) {
            setTimeout(() => {
              this.tabChang(3)
            }, 100)
          }
        })
      })
    },
    timeMode() {
      let num = moment(new Date()).format('HH')
      return Number(num) > 11 ? '下午' : '上午'
    },
    getDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    getTime(date) {
      return moment(date).format('MM-DD HH:mm')
    },
    getRate(num) {
      return `${(num / 500) * 100}%`
    },
    formatDate () {
      this.time = moment(new Date()).format('HH:mm')
    },
    tabChang(num) {
      this.tabIndex1 = num
      if (num === 1) {
        this.list1 = this.list2
        this.total1 = this.total2
      } else if (num === 2) {
        this.list1 = this.list3
        this.total1 = this.total3
      } else if (num === 3) {
        this.list1 = this.list4
        this.total1 = this.total4
      }
    },
    tabChang2(num) {
      this.tabIndex2 = num
    },
    chart1(data) {
      let myChart = echarts.init(document.getElementById('pie1'));
      let option ={
        tooltip: {
          trigger: 'item',
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = {top: 60};
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          }
        },
        color: ['#00B4FF','#75E670','#76D8FD','#28DBD7'],
        legend: {
          orient: 'vertical',
          left: 'center',
          top:'bottom',
          icon: 'circle',
          textStyle: {
            color: '#fff'
          },
          formatter: (name) => {
            let data = option.series[0].data;
            let total = 0;
            let tarValue = 0;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            return  name + ' ' + tarValue + '位' ;
          }
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '50%',
            center: ['50%', '30%'],
            data: [
              { value: data.sixty_75, name: '60~75岁' },
              { value: data.seventysix_90, name: '76-90岁' },
              { value: data.ninety_100, name: '90-100岁' },
              { value: data.over100, name: '100岁以上' },
            ],
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    chart2(data) {
      let myChart = echarts.init(document.getElementById('pie2'));
      let option ={
        tooltip: {
          trigger: 'item'
        },
        color: ['#00B4FF','#75E670','#76D8FD','#28DBD7'],
        legend: {
          orient: 'vertical',
          left: 'center',
          top:'55%',
          icon: 'circle',
          textStyle: {
            color: '#fff'
          },
          formatter: (name) => {
            let data = option.series[0].data;
            let total = 0;
            let tarValue = 0;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            return  name + ' ' + tarValue + '位' ;
          }
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '50%',
            center: ['50%', '30%'],
            data: [
              { value: data.manNumber, name: '男' },
              { value: data.woManNumber, name: '女' }
            ],
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    chart3(data_) {
      let myChart = echarts.init(document.getElementById('pie3'));
      let option ={
        tooltip: {
          trigger: 'item'
        },
        color: ['#00B4FF','#75E670','#76D8FD','#28DBD7'],
        legend: {
          type: 'scroll',
          orient: 'vertical',
          pageIconColor: '#2C86FF', //翻页箭头颜色
          pageIconInactiveColor: 'rgba(44,132,251,0.40)', //翻页（即翻页到头时箭头的颜色）
          pageIconSize: 10, //翻页按钮大小
          itemWidth: 16,  // 设置宽度
          itemHeight: 16, // 设置高度
          pageTextStyle: {
            color: '#999', //翻页数字颜色
          }, //翻页数字设置
          right: 'center',
          top:'bottom',
          icon: 'circle',
          height: 70,
          textStyle: {
            color: '#fff'
          },
          formatter: (name) => {
            let data = option.series[0].data;
            let total = 0;
            let tarValue = 0;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            return  name + ' ' + tarValue + '位' ;
          }
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '50%',
            center: ['50%', '30%'],
            data: Object.keys(data_).map(item => {
              return {
                value: data_[item],
                name: item
              }
            }),
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
  },
  beforeDestroy() {
    if (this.timesetInterval) {
      clearInterval(this.timesetInterval); // 在Vue实例销毁前，清除时间定时器
      this.timesetInterval = null
    }
    if (this.listsetInterval) {
      clearInterval(this.listsetInterval); // 在Vue实例销毁前，清除时间定时器
      this.listsetInterval = null
    }
    if (this.weatherInterval) {
      clearInterval(this.weatherInterval); // 在Vue实例销毁前，清除时间定时器
      this.weatherInterval = null
    }
  }
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.cockpit {
  // width: 1920px;
  // height: auto;
  // margin: auto;
  // text-align: center;
  // color: #fff;
  //background-color: #116db6;
  background-image: url("~@/assets/bg.png");
  // height:1080px;
  height: 100%;
  width:100%;
  //overflow: hidden;
  // background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;

  .header {
    display: flex;
    justify-content: space-around;
    .header-name span {
      font-size: 20px;
      color: #1975ff;
      font-weight: bold;
    }
    .header-name img {
      width: 15px;
      height: 10px;
      margin-left: 10px;
    }
    .header-img {
      width: 100%;
      height: 94px;
      background: url("~@/assets/dbkuang.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      position: relative;

      .header-left{
        .item{
          display: flex;
          align-items: flex-end;
          color: #fff;
          margin-right: 30px;
          .div{
            display: flex;
            flex-direction:column-reverse;
          }
          span{
            font-size: 24px;
            margin-left: 5px;
          }
        }
      }
    }
    .header-img span {
      font-size: 40px;
      color: #fff;
    }
    .header-message {
      position: relative;
    }
    .math {
      width: 20px;
      height: 20px;
      background-color: #e56c21;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: 40px;
      left: 90px;
    }
    .header-message img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-left: 10px;
    }
    .header-message span {
      vertical-align: middle;
    }

  }
  .option {
    padding: 30px;
    padding-top: 35px;
    display: flex;
    &-card{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &_title{
        display: flex;
        .title{
          color: #fff;
          margin-left: 10px;
          font-size: 20px;
          white-space: nowrap;
        }
      }

      &_num{
        margin-top: 10px;
        display: flex;
        align-items: flex-end;
        .num{
          font-size: 40px;
          color: #39C0FF;
        }
        .unit{
          color: #fff;
          font-size: 20px;
          margin-left: 2px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .main{
    padding: 30px;
    padding-bottom: 0;
    // height:calc(100% - 255px);
    height: 820px;
    display: flex;
    justify-content: space-between;

    &-left{
      flex: 14;
      &_top{
        margin-bottom: 20px;
        .title{
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 20px;
          margin-left: 6%;
          margin-bottom: -8px;

          img{
            margin-left: 10px;
          }
        }

        &_mian{
          background: url("~@/assets/kuang1.png");
          background-size: 100% 100%;
          height: 240px;
          padding: 20px 10px 10px 10px;

          display: flex;

          .echart{
            flex: 1;
          }
        }
      }

      &_middle{
        margin-bottom: 20px;
        .title{
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 20px;
          margin-left: 6%;
          margin-bottom: -8px;

          img{
            margin-left: 10px;
          }
        }

        &_mian{
          background: url("~@/assets/kuang2.png");
          background-size: 100% 100%;
          height: 210px;
          padding: 10px;

          .tab{
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding-top: 20px;
            &_opiton{
              padding: 5px 20px;
              color: #fff;
              font-size: 14px;
              background: rgba(10,71,126, .7);
              border: 1px solid rgba(10,71,126, .7);
              border-radius: 15px;
            }
            &_opiton-action{
              border: 1px solid #0297DC;
            }
          }
          .pic{
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 10px;
            margin-top: 15px;
            img{
              width: 145px;
              height: 160px;
              object-fit: fill;
            }
          }
        }
      }

      &_bottom{
        .title{
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 20px;
          margin-left: 6%;
          margin-bottom: -8px;

          img{
            margin-left: 10px;
          }
        }

        &_mian{
          background: url("~@/assets/kuang3.png");
          background-size: 100% 100%;
          padding: 10px;
          height: 220px;

          .header{
            color: #fff;
            text-align: center;
            margin-top: 30px;
            font-size: 14px;
            font-weight: bolder;
          }
          .opiton{
            color: #fff;
            font-size: 14px;
            text-align: center;

            .detail{
              white-space: nowrap;
              overflow: hidden;//文本超出隐藏
              text-overflow: ellipsis;//文本超出省略号替代
            }
          }
        }


      }
    }
    &-middle{
      flex: 22;
      width: 100%;
      height: calc(100% - 40px);
      // height: 100%;
      margin: 20px 20px 0;
    }
    &-right{
      flex: 14;

      &_top{
        margin-bottom: 20px;
        .title{
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 20px;
          margin-left: 6%;
          margin-bottom: -8px;

          img{
            margin-left: 10px;
          }


        }
        &_mian{
          background: url("~@/assets/kuang4.png");
          background-size: 100% 100%;
          height: 330px;
          padding: 10px;

          .tab{
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding-top: 20px;
            &_opiton{
              padding: 5px 20px;
              color: #fff;
              font-size: 14px;
              background: rgba(10,71,126, .7);
              border: 1px solid rgba(10,71,126, .7);
              border-radius: 15px;
            }
            &_opiton-action{
              border: 1px solid #0297DC;
            }
          }

          .content{
            color: #97A1AF;
            font-size: 13px;
            padding-top: 20px;
            .box{
              border: 1px solid #4E70A0;
              height: 12px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              .line{
                height: 6px;
                margin: 0 1px;
                background: #28A8F3;
                border:1px solid transparent;
                border-radius: 6px;
              }
            }
          }

          .scrollbar{
            height: 240px;
            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */
            overflow-x: hidden;
            overflow-y: auto;
          }
        }
      }

      &_bottom{
        .title{
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 20px;
          margin-left: 6%;
          margin-bottom: -8px;

          img{
            margin-left: 10px;
          }
        }

        &_mian{
          background: url("~@/assets/kuang5.png");
          background-size: 100% 100%;
          padding: 10px;
          height: 380px;
          &-header{
            display: flex;
            color: #fff;
            text-align: center;
            margin-top: 30px;
            font-size: 14px;
            font-weight: bolder;
            margin-bottom: 10px;
            div{
              flex: 1;
            }
          }

          &-opiton{
            display: flex;
            color: #97A1AF;
            font-size: 14px;
            text-align: center;

            div{
              flex: 1;
              text-align: left;
              .ellipsis{

                display:-webkit-box;
                -webkit-box-orient:vertical;  //垂直排列
                -webkit-line-clamp:1;  //限制行数
                overflow:hidden;
                text-align: left;
              }
            }


          }

          .header{
            color: #fff;
            text-align: center;
            margin-top: 30px;
            font-size: 14px;
            font-weight: bolder;
          }
          .opiton{
            color: #97A1AF;
            font-size: 14px;
            text-align: center;

            .detail{
              white-space: nowrap;
              overflow: hidden;//文本超出隐藏
              text-overflow: ellipsis;//文本超出省略号替代
            }
          }
        }
      }
    }
  }
}
.alarm-con {
  height: calc(100% - 70px);
  overflow-y: scroll;
}

</style>

<style lang="scss">
.date {
  background-color: #114b7d;
  border: 1px solid #114b7d;
  // border: none;
  color: #fff;
  margin-bottom: 10px;

  .el-input__inner{
    background-color: #114b7d !important;
    border: 1px solid #114b7d !important;
    color: #fff !important;
  }
}

.popper__arrow::after {
  border-bottom-color: #114b7d !important;
}
.el-picker-panel{
  color: #fff !important;
  border: 1px solid #114b7d !important;
  background: #114b7d !important;
}
.el-month-table td .cell{
  color: #fff !important;
}
.el-year-table td .cell{
  color: #fff !important;
}
.el-date-picker__header-label{
  color: #fff !important;
}
.el-picker-panel__icon-btn{
  color: #fff !important;
}
</style>