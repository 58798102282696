import request from "../utils/request";

// 根据uid获取token
export const getRtcToken = (uid) => {
  return request({
    url: "/ophApi/baiduFace/getRtcToken",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data: {
      uid,
      customerTypeIds: 1
    },
  });
};

// 根据设备deviceSn获取appId
export const getCallAppId = (iotSn) => {
  return request({
    url: "/ophApi/baiduFace/getIotMsg",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data: {
      iotSn,
      customerTypeIds: 1
    }
  })
}

// 坐席登录
export const loginDuer = (data) => {
  return request({
    url: "/ophApi/talkSeat/checkPassword",
    method: "post",
    data: {
      customerTypeIds: 1,
      ...data
    }
  })
};