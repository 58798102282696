import axios from "axios";
import request from "../utils/request";
//机构
export const branchList = (data) =>
  request({
    url: "/ophApi/iotBi/branchList",
    method: "GET",
      data: {
          customerTypeIds: 1,
          ...data
      },
  });
//楼宇
export const getList = ({ branchId }) =>
  request({
    url: "/ophApi/iotBi/buildingList",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "GET",
    params: {
      branchId: branchId,
      customerTypeIds: 1,
    },
  });
//设备信息
export const getDiagram = ({ buildingId }) =>
  request({
    url: "/ophApi/iotBi/diagram",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "GET",
    params: {
      buildingId: buildingId,
      customerTypeIds: 1,
    },
  });
//离线设备
export const listBean = ({ buildingId, connStatus, type, page, rows }) =>
  request({
    url: "/ophApi/iotDevice/listBean",
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    data: {
      customerTypeIds: 1,
      buildingId,
      connStatus,
      type,
      page,
      rows,
    },
  });
//websocket
export const getScreenUUid = (params) => {
  return axios.request({
    method: "get",
    url: "/ophApi/callInfo/bigScreenUUID",
    params: {
        ...params,
        customerTypeIds: 1,
    },
  });
};
//报警列表
export const listBao = ({ buildingId, status }) =>
  request({
    url: "/ophApi/callInfo/listBean",
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    data: {
      buildingId,
      status,
      customerTypeIds: 1,
    },
  });
//确认
export const getCall = ({ id }) =>
  request({
    url: "/ophApi/callInfo/responseCall",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    params: {
      id: id,
      customerTypeIds: 1,
    },
  });

// 获取街道列表
export const getCommunityList = (data) =>
  request({
    url: "/ophApi/communityInitialization/listTown",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });

// 根据街道id获取社区列表
export const getCommunityListByCommunityId = (data) =>
  request({
    url: "/ophApi/communityInitialization/listCommunity",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });

// 街道id查询所含床位
export const getBedListByCommunityId = (data) =>
  request({
    url: "/ophApi/communityInitialization/getBedListByStreetCommunityId",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });

// 紧急处理列表
export const getEmergencyList = (data) =>
  request({
    url: "/ophApi/callInfo/screenlistBean",
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
// 报警列表
export const getAlarmList = (data) =>
  request({
    url: "/ophApi/callInfo/screenlistBean",
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
// 服务工单
export const getServiceList = (data) =>
  request({
    url: "/ophApi/serveRequest/listByPageBean",
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
// "beginRequestTime": "",
//     "endRequestTime": ""
// 服务人次数
export const getlistItemAll = (data) =>
  request({
    url: "/ophApi/serveRequest/listItemByReceived",
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });

// 获取全部床位-根据街道id
export const getBedListByStreetCommunityId = (data) =>
  request({
    url: "/ophApi/communityInitialization/getBedListByStreetId",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });

// 事件处理件数
export const getlistItemBySuccess = (data) =>
  request({
    url: "/ophApi/serveRequest/listItemBySuccess",
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
// 按乡镇
export const getlistItemGroupByTown = (data) =>
    request({
        url: "/ophApi/serveRequest/listItemGroupByTown",
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        data: {
            customerTypeIds: 1,
            ...data
        }
    });
// 数据统计
export const getYlcStatic = (data) =>
  request({
    url: "/ophApi/bigScreen/ylcStatic",
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
// 获取今日关注
export const getTodayAttention = (data) =>
  request({
    url: "/ophApi/bigScreen/emergencyAlarmToday",
    method: "POST",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });

// 床位管理-列表
export const getBedListByBranch = (data) => {
  return request({
    url: "/ophApi/communityInitialization/getBedListByBranch",
    method: "post",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
};

// 获取热力图数据
export const getHeatMapData = (data) => {
  return request({
    url: "/ophApi/bigScreen/elderCallAndServiceInfo?",
    method: "post",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
};

// 获取饼图数据
export const getPieData = (data) => {
  return request({
    url: "/ophApi/bigScreen/elderInfoStatic?",
    method: "post",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
};

// 获取当前天气
export const getWeather = city => {
  return request({
    url: "/weather/now",
    method: "get",
    params: {
      city,
      customerTypeIds: 1,
    },
  });
}

// 获取右侧紧急处理列表
export const getRightEmergencyList = (data) => {
  return request({
    url: "/ophApi/bigScreen/screenlistBean",
    method: "post",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
}

// 数据统计
export const getStaticData = (data) => {
  return request({
    url: "/ophApi/bigScreen/dataStatistics",
    method: "post",
      data: {
          customerTypeIds: 1,
          ...data
      }
  });
}
// 派遣人员统计
export const statisticsByUser = (data) =>
    request({
        url: "/ophApi/serveRequest/statisticsByUser",
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        data: {
            customerTypeIds: 1,
            ...data
        }
    });