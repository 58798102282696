<template>
  <ScreenAdapter>
    <div class="home">
      <duer-rtc ref="duer" @close-call="closeCall" @login-status="changeStatus"></duer-rtc>
      <div class="header">
        <div
          class="header-img"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <img :src="header1logo" alt="" />
          <div style="position: absolute; top: 35px; left: 20px; display: flex">
            <select
              name=""
              class="select-upp"
              v-model="building"
              @change="getbuilding($event)"
            >
              <option
                v-for="(build, bdindex) in buildingList"
                :key="bdindex"
                :value="build.id"
                :label="build.streetName"
              ></option>
            </select>
          </div>
          <div
            style="position: absolute; top: 35px; left: 141px; display: flex"
          >
            <div
              class="tab-btn"
              @click="tabSwitch(1)"
              style="margin-left: 10px"
              :class="{ 'tab-btn_action': tabIndex === 1 }"
            >
              健康管理床位图
            </div>
            <div
              class="tab-btn"
              @click="tabSwitch(2)"
              style="margin-left: 10px"
              :class="{ 'tab-btn_action': tabIndex === 2 }"
            >
              照护监控地图
            </div>
          </div>
          <div class="full-screen" @click="fullScreen"></div>
          <div class="login-call" @click="showLogin = true" :style="{backgroundImage: `url(${call_icon})`}"></div>
        </div>
        <div class="header-message"></div>
      </div>
      <div class="theme">
        <bed
          ref="bed"
          v-if="tabIndex === 1 && communityList"
          :communityList="communityList"
          @call="showMakeCallDialog"
          @play="playAudio"
        ></bed>
        <monitor
          ref="monitor"
          v-if="tabIndex === 2"
          :communityList="communityList"
          :streetId="building"
        ></monitor>
      </div>
    </div>
    <!-- 呼叫音箱表单信息 -->
    <el-dialog title="音箱呼叫" :visible.sync="showMakeCall" append-to-body>
      <el-form ref="callForm" :model="callForm" :rules="callRules">
        <el-form-item label="appId：" label-width="100px" v-show="0">
          <el-input v-model="callForm.appId" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="uid：" label-width="100px" v-show="0">
          <el-input v-model="callForm.uid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="被叫姓名：" label-width="100px">
          <el-input v-model="callForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="呼叫方式：" label-width="100px">
          <el-select v-model="callForm.callMode" placeholder="请选呼叫方式">
            <el-option label="视频通话" value="0"></el-option>
            <el-option label="语音通话" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showMakeCall = false">取 消</el-button>
        <el-button type="primary" @click="checkCallForm">呼 叫</el-button>
      </div>
    </el-dialog>
    <!-- 接听坐席登录 -->
    <el-dialog title="接听坐席登录" :visible.sync="showLogin" append-to-body width="600px">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
        <el-form-item label="坐席编号：" label-width="100px">
          <el-input v-model="loginForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="坐席密码：" label-width="100px">
          <el-input
            v-model="loginForm.password"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="warning" @click="logOut">结束接听</el-button>
        <el-button @click="showLogin = false">取 消</el-button>
        <el-button type="primary" @click="checkLoginForm">开始接听</el-button>
      </div>
    </el-dialog>
  </ScreenAdapter>
</template>
<script>
import {
  branchList,
  getList,
  getDiagram,
  listBean,
  getScreenUUid,
  listBao,
  getCall,
  getCommunityList,
  getCommunityListByCommunityId,
  getBedListByStreetCommunityId,
} from "@/api/branch";
import { loginDuer } from "@/api/duer";
import bed from "@/components/bed"; // 健康管理床位图
// import bed from "@/components/bedCopy"; // 健康管理床位图
import monitor from "@/components/monitor"; // 照护监控地图
import ScreenAdapter from "@/components/ScreenAdapter"; // 自适应
import duerRtc from "@/components/duerRtc";
const callRules = {
  appId: [
    { required: true, message: "请输入appId", trigger: "blur" },
    { min: 1, max: 32, message: "长度在 1 到 32 个字符", trigger: "blur" },
  ],
  uid: [
    { required: true, message: "请输入uid", trigger: "blur" },
    { min: 1, max: 32, message: "长度在 1 到 32 个字符", trigger: "blur" },
  ],
  callMode: [{ required: true, message: "请选择呼叫方式", trigger: "blur" }],
}
const loginRules = {
  account: [
    { required: true, message: "请输入坐席编号", trigger: "blur" },
    { min: 1, max: 32, message: "长度在 1 到 32 个字符", trigger: "blur" },
  ],
  password: [
    { required: true, message: "请输入坐席密码", trigger: "blur" },
    { min: 1, max: 32, message: "长度在 1 到 32 个字符", trigger: "blur" },
  ],
}
// 是否全屏
const isFullScreen =
  document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen;
// 进入全屏
const requestFullscreen = (el = null) => {
  const ele = el || document.documentElement;
  const rfs =
    ele.requestFullscreen ||
    ele.webkitRequestFullscreen ||
    ele.mozRequestFullScreen ||
    ele.msRequestFullscreen;
  // 如果全屏，返回
  if (isFullScreen) return;
  if (rfs) {
    rfs.call(ele);
  } else if (typeof window.ActiveXObject !== "undefined") {
    const wscript = new ActiveXObject("WScript.Shell");
    if (wscript) {
      wscript.SendKeys("{F11}");
    }
  }
};
export default {
  watch: {
    isLogin(val) {
      if (val) {
        this.call_icon = require("@/assets/online.gif");
      } else {
        this.call_icon = require("@/assets/offline.gif");
      }
    },
  },
  components: {
    bed,
    ScreenAdapter,
    monitor,
    duerRtc,
  },
  data() {
    return {
      callRules,
      loginRules,
      showLogin: false,
      isLogin: false,
      call_icon: require("@/assets/offline.gif"),
      girlzc: require("@/assets/gril.png"),
      boyzc: require("@/assets/boy.png"),
      ldgzz: require("@/assets/dd_gzz.png"),
      cdgzz: require("@/assets/cd_gzz.png"),
      bjgzz: require("@/assets/hjbj_gzz.png"),
      ldclz: require("@/assets/dd_dcl.png"),
      cdclz: require("@/assets/cd_dcl.png"),
      bjclz: require("@/assets/hjbj_dcl.png"),
      ldlxz: require("@/assets/dd_lx.png"),
      cdlxz: require("@/assets/cd_lx.png"),
      bjlxz: require("@/assets/hjbj_lx.png"),
      ldwrz: require("@/assets/dd_kong.png"),
      cdwrz: require("@/assets/cd_kong.png"),
      bjwrz: require("@/assets/hjbj_kong.png"),
      headerlogo: require("@/assets/header-logo.png"),
      // header1logo: require("@/assets/header-commen-logo.png"),
      header1logo: require("@/assets/header_1_logo.png"),
      list: [],
      activeName: "0",
      show: false,
      message: false,
      messageDialog: false,
      bgDialog: false,
      branch: 0,
      branchList: [],
      buildingList: [],
      building: 0,
      communityList: [],
      Diagram: [],
      data: [],
      totalList: [],
      radarTotalInfo: {},
      bedMonitorInfo: {},
      callAlarmInfo: {},
      room: [],
      floorId: "",
      dialogVisible: false,
      tableData: [],
      timeoutObj: null,
      serverTimeoutObj: null,
      timeout: 20 * 1000,
      websock: null,
      isHeartbeat: false,
      sessionKey: "",
      bjmessage: [],
      bjaudio: "",
      bjmessagelist: [],
      bjmessageleft: [],
      tankMessage: [],
      audio: new Audio(),
      query: {
        page: "",
        rows: 10,
      },
      tabIndex: 1,
      total: 0,
      publicDeviceList: [],
      oreder: true,
      flatRoomList: [], // 房间列表，扁平化数组]
      showMakeCall: false,
      callForm: {
        name: "",
        callMode: "0",
      },
      loginForm: {
        account: "",
        password: "",
      },
    };
  },
  async created() {
    document.title = '开化县智能设备报警监视大屏'
    await this.getbranchList();
  },
  methods: {
    checkLoginForm() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          loginDuer({
            account: this.loginForm.account,
            password: this.loginForm.password,
          }).then(res => {
            console.log('坐席登录：', res)
          })
          this.isLogin = true;
          this.showLogin = false;
          const appId = 'c5s8ctmf';
          const uid = this.loginForm.account;
          this.$nextTick(() => {
            this.$refs.duer.init(appId, uid);
          });
        }
      });
    },
    logOut() {
      try {
        this.$refs.duer.logout();
        this.showLogin = false;
        this.isLogin = false;
      } catch (e) {
        console.log(e);
      }
    },
    fullScreen() {
      if (!document.fullscreenElement) {
        requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    tabSwitch(num) {
      this.tabIndex = num;
      if (num === 1) {
        this.$nextTick(() => {
          if (this.$refs.bed.activeName === 0) {
            this.$nextTick(() => {
              this.$refs.bed.getAllBed();
            });
          } else {
            this.$nextTick(() => {
              this.$refs.bed.getBedList();
            });
          }
        });
      }
    },
    bulding(index, floorId) {
      this.activeName = index;
      // for (let i in this.list) {
      //   if (this.list[i].floorId == floorId) {
      //     this.data = this.list[i].roomList;
      //   }
      // }

      if (floorId == null) {
        for (let j in this.list) {
          this.data = this.list[j].publicDeviceList;
          this.message = true;
          this.oreder = false;
        }
      } else {
        let arr = [];
        for (let i in this.list) {
          this.floorId = this.list[index].floorId;
          if (this.list[i].floorId == this.floorId) {
            this.data = this.list[i].roomList;
            this.list[i].roomList.forEach((element) => {
              element.bedList.forEach((bed) => {
                bed.roomNo = element.roomNo;
              });
              arr.push(element.bedList);
            });
          }
        }
        this.flatRoomList = arr.flat();
        console.log(this.flatRoomList, "房间");
        this.message = false;
        this.oreder = true;
      }
    },
    handleSizeChange(rows) {
      this.query.rows = rows;
      this.getldList();
      // this.getbjList()
    },
    handleCurrentChange(page) {
      this.query.page = page;
      this.getldList();
      // this.getbjList()
    },
    getldList(type) {
      var data = {
        buildingId: this.building,
        connStatus: 0,
        type: type,
        rows: this.query.rows,
        page: this.query.page,
      };
      listBean(data).then((res) => {
        // console.log("离线", res);
        this.tableData = res.rows;
        // this.tableData = [...res.rows, ...res.rows, ...res.rows];
        this.total = res.total;
      });
    },
    playAudio() {
      // console.log("声音", this.audio);
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio.src = this.bjaudio;
      this.audio.load();
      this.audio.play();
    },
    takemessage() {
      this.messageDialog = true;
    },
    getout() {
      this.messageDialog = false;
    },
    Dialogshow(data) {
      this.tankMessage = data;
      this.show = true;
    },
    upknow(id) {
      getCall({ id: id }).then((res) => {
        // console.log(res);
      });
      this.show = false;
    },
    refreshDiagram() {
      // 获取房态图
      getDiagram({ buildingId: this.building }).then((res) => {
        // 刷新房态图前，先重置选中的楼层
        this.activeName = "0";
        this.list = res.floorList;
        console.log("list", this.list);
        this.totalList = res.totalList;
        if (this.totalList && this.totalList.length > 0) {
          this.totalList.forEach((deviceTotal) => {
            if (deviceTotal.deviceType === 1) {
              this.radarTotalInfo = deviceTotal;
            } else if (deviceTotal.deviceType === 5) {
              this.bedMonitorInfo = deviceTotal;
            } else if (deviceTotal.deviceType === 0) {
              this.callAlarmInfo = deviceTotal;
            }
          });
        }
        let arr = [];
        for (let i in this.list) {
          this.floorId = this.list[0].floorId;
          if (this.list[i].floorId == this.floorId) {
            this.data = this.list[i].roomList;
            this.list[i].roomList.forEach((element) => {
              element.bedList.forEach((bed) => {
                bed.roomNo = element.roomNo;
              });
              arr.push(element.bedList);
            });
          }
        }
        this.flatRoomList = arr.flat();
        console.log(this.flatRoomList, "房间");
      });
      // 刷新左右报警列表
      this.getlistleft(this.building);
      this.getlistbao(this.building);
    },
    async getbranchList() {
      this.activeName = "0";
      branchList().then((res) => {
        console.log(res, "branchList");
        let branchId = this.$route.query.branchId;
        this.branchList = res;
        if (branchId) {
          this.branch = Number(branchId);
        } else {
          this.branch = 9543;
        }
        getCommunityList({ page: 1, rows: 999, branchId: this.branch }).then(
          (res) => {
            console.log(res, "getCommunityList");
            this.buildingList = res.data.data;
            this.building = this.buildingList[0].id;
            // this.refreshDiagram();
            getCommunityListByCommunityId({
              page: 1,
              rows: 999,
              branchId: this.branch,
              streetCommunityId: this.building,
              townId: this.building,
            }).then((res) => {
              console.log(res, "getCommunityListByCommunityId");
              this.communityList = res.data.data;
              this.communityList.unshift({
                id: this.building,
                communityName: "全部",
              });
              // getBedListByStreetCommunityId({
              //   streetId: this.building
              // }).then (res => {
              //   this.communityList = res.rows;
              // })
            });
          }
        );
        getScreenUUid({ branchId: this.branch }).then((res) => {
          if (res.data) {
            this.sessionKey = res.data;
            this.initWebSocket(res.data);
          }
        });
      });
    },
    setbranch(event) {
      this.activeName = "0";
      this.message = false;
      this.oreder = true;
      this.branch = event.target.value;
      getList({ branchId: this.branch }).then((res) => {
        this.buildingList = res;
        this.building = res[0].id;
        getCommunityListByCommunityId({
          page: 1,
          rows: 999,
          branchId: this.branch,
          streetCommunityId: this.building,
          townId: this.building,
        }).then((res) => {
          console.log(res, "getCommunityListByCommunityId");
        });
        // this.refreshDiagram();
      });
      getScreenUUid({ branchId: this.branch }).then((res) => {
        // console.log("ksaj", res)
        if (res.data) {
          this.sessionKey = res.data;
          this.initWebSocket(res.data);
        }
      });
    },
    getbuilding(event) {
      this.building = Number(event.target.value);
      console.log("切换楼宇", this.building);
      // this.refreshDiagram();
      getCommunityListByCommunityId({
        page: 1,
        rows: 999,
        branchId: this.branch,
        streetCommunityId: this.building,
        townId: this.building,
      }).then((res) => {
        console.log(res, "getCommunityListByCommunityId");
        if (this.tabIndex === 2) {
          this.$refs.monitor.detailDialog = false;
          this.$refs.monitor.getAlarmList();
        }
        this.communityList = res.data.data;
        this.communityList.unshift({
          id: this.building,
          communityName: "全部",
        });
      });
    },
    getldMessage(type) {
      this.query.page = null;
      this.getldList(type);
      this.dialogVisible = true;
    },
    getcdMessage(type) {
      this.query.page = null;
      this.getldList(type);
      this.dialogVisible = true;
    },
    //右侧报警列表
    getlistbao(buildingId) {
      // console.log(89, buildingId);
      var data = {
        buildingId: buildingId,
        status: 1,
      };
      listBao(data).then((res) => {
        this.bjmessagelist = res.rows;
        //  console.log(89, this.bjmessagelist);
      });
    },
    //左侧报警列表
    getlistleft(buildingId) {
      // console.log(89, buildingId);
      var data = {
        buildingId: buildingId,
        status: 0,
      };
      listBao(data).then((res) => {
        //  console.log(79, res);
        this.bjmessageleft = res.rows;
        if (this.bjmessageleft != "") {
          this.bgDialog = true;
          this.show = true;
          this.tankMessage = res.rows[0];
        } else if (res.rows.length == 0) {
          this.bgDialog = false;
          this.show = false;
        }
      });
    },
    getbjMessage(type) {
      this.query.page = null;
      this.getldList(type);
      this.dialogVisible = true;
    },
    async initWebSocket(uuidres) {
      //  console.log(11111, uuidres)
      const protocol = location.protocol;
      let websocketProtocol;
      if (protocol.indexOf("https") === -1) {
        // 找不到https
        websocketProtocol = "ws";
      } else {
        websocketProtocol = "wss";
      }
      const url =
        `${websocketProtocol}://${location.host}/ophApi/websocket?param=guest` +
        uuidres;
      this.websock = new WebSocket(url);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      console.log("WebSocket连接成功");
      this.startOpenHeartbeat();
    },
    websocketonmessage(e) {
      const reData = JSON.parse(e.data);
      console.log("推送数据:", reData);
      switch (reData.msgBody) {
        case "ping":
          break;
        case "心率/呼吸推送":
          this.$nextTick(() => {
            this.$refs.bed.reFresh(reData.params);
          });
          break;
        default:
          if (this.tabIndex === 1) {
            this.$nextTick(() => {
              this.$refs.bed.getRightAlarmList();
              this.$refs.bed.openDialog(reData);
              this.tabSwitch(1);
            });
            if (reData.bizType == 1) {
              this.bjaudio = reData.params.mp3Url;
              this.playAudio();
            } else if (reData.bizType == 2) {
              this.$nextTick(() => {
                this.$refs.bed.getRightAlarmList();
                this.tabSwitch(1);
              });
            }
          } else {
            setTimeout(() => {
              this.$refs.monitor.getAlarmList();
              this.$refs.monitor.getBedList();
            }, 0);
          }
          break;
      }
      this.reset();
    },
    websocketonerror(e) {
      //连接建立失败重连
      // console.log("连接失败", e);
      this.reconnect();
    },
    startOpenHeartbeat() {
      //开启心跳
      let self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(() => {
        if (self.websock.readyState == 1) {
          var sendMsg = {
            sessionKey: "guest" + this.sessionKey,
            msgBody: "ping",
          };
          self.websock.send(JSON.stringify(sendMsg));
        } else {
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.websock.close();
        }, self.timeout);
      }, self.timeout);
    },
    reset() {
      //重置心跳
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.startOpenHeartbeat();
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.isHeartbeat) {
        return;
      }
      that.isHeartbeat = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.initWebSocket();
        that.isHeartbeat = false;
      }, 5000);
    },
    checkCallForm() {
      this.$refs["callForm"].validate((valid) => {
        if (valid) {
          if (this.isLogin) {
            this.$refs.duer.makeCall(
              this.callForm.appId,
              this.callForm.uid,
              +this.callForm.callMode,
              this.callForm.name
            )
          } else {
            this.$message.error("请先登录坐席！");
          }
        } else {
          return false;
        }
      });
    },
    showMakeCallDialog(appId, uid, name, type) {
      this.showMakeCall = true;
      this.$set(this.callForm, "appId", appId);
      this.$set(this.callForm, "uid", uid);
      this.$set(this.callForm, "name", name);
      this.$set(this.callForm, "callMode", type);
    },
    closeCall() {
      this.showMakeCall = false;
      this.$set(this.callForm, "appId", '');
      this.$set(this.callForm, "uid", '');
      this.$set(this.callForm, "name", '');
    },
    changeStatus(status) {
      this.isLogin = status;
    }
  },
};
</script>
<style scoped lang="scss">
#app {
  //  width: 1920px;
  // height: 1080px;
  // overflow: hidden;
}
.home {
  width: 100%;
  // height: 100%;
  height: 100vh;
  // height: auto;
  // margin: auto;
  // text-align: center;
  // color: #fff;
  //background-color: #116db6;
  // background-image: url("~@/assets/bg.png");
  // background-size: 100% 100%;
  // position: relative;
  // overflow-y: scroll;
  // overflow-x: hidden;
  .header {
    display: flex;
    justify-content: space-around;
    .header-name span {
      font-size: 20px;
      color: #1975ff;
      font-weight: bold;
    }
    .header-name img {
      width: 15px;
      height: 10px;
      margin-left: 10px;
    }
    .header-img {
      width: 100%;
      height: 94px;
      background: url("~@/assets/dbkuang.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      position: relative;
    }
    .header-img span {
      font-size: 40px;
      color: #fff;
    }
    .header-message {
      position: relative;
    }
    .math {
      width: 20px;
      height: 20px;
      background-color: #e56c21;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: 40px;
      left: 90px;
    }
    .header-message img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-left: 10px;
    }
    .header-message span {
      vertical-align: middle;
    }
    .select-upp-a {
      width: 180px;
      height: 30px;
      background-color: #116db6;
      // border: 1px solid #8DCED4;
      border: none;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
    .select-upp {
      width: 120px;
      height: 30px;
      padding: 0 10px;
      background-color: #114b7d;
      border: 1px solid #114b7d;
      border-radius: 15px;
      // border: none;
      color: #fff;
    }
    .tab-btn {
      min-width: 100px;
      text-align: center;
      background-color: #114b7d;
      border: 1px solid #114b7d;
      padding: 5px;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
    .tab-btn_action {
      border: 1px solid #2682ba;
    }
  }
  .theme {
    margin-top: 42px;
    // height: calc(100% - 136px);
    height: calc(100% - 236px);
    // overflow-y: hidden;
  }

  .bulingConent {
    width: 97%;
    display: flex;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    // margin: 0 20px;
    margin-left: 20px;
  }
  .order-mg {
    width: 8%;
    height: 200px;
    background-color: #2a80bd;
    border-radius: 10px;
    margin-left: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  .order-o .order-mg:first-child {
    width: 100%;
    height: 200px;
    background-color: #2a80bd;
    border-radius: 10px 0 0 10px;
    // margin-left: 5px;
  }
  .order-o .order-mg:last-child {
    width: 100%;
    height: 200px;
    background-color: #2a80bd;
    border-radius: 0 10px 10px 0;
    // margin-left: 5px;
  }
  .order-mg-a {
    width: 105px;
    height: 200px;
    background-color: #092467;
    // border-radius: 0 10px 10px 0;
    margin-left: 5px;
  }
  .order-name {
    width: 100%;
    height: 60px;
    background-color: #3f8fc0;
    // border-radius: 15px 0 0 0;
  }
  .order-name-a {
    width: 100%;
    height: 60px;
    background-color: #a14b18;
    // border-radius: 15px 0 0 0;
  }
  .order-name-b {
    width: 100%;
    height: 60px;
    background-color: #a9ac69;
    // border-radius: 15px 0 0 0;
  }
  .order-name-two {
    width: 100%;
    height: 60px;
    background-color: #3f8fc0;
    border-radius: 0 15px 0 0;
  }
  .Dialog {
    width: 600px;
    height: 250px;
    // background: rgba(91, 167 ,201 , 0.75);
    background-color: #8dcdd6;
    border-radius: 15px;
    position: absolute;
    bottom: 400px;
    left: 700px;
    .laba {
      position: absolute;
      bottom: 85%;
      left: 90%;
    }
  }
  .button {
    width: 100px;
    height: 30px;
    background-color: #8dcdd6;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    margin-left: 450px;
    margin-top: 50px;
  }
  .workarea-sb {
    width: 220px;
    min-height: 155px;
    background-color: #3f8fc0;
    border-radius: 10px;
    margin-top: 5px;
    .number {
      width: 75%;
      margin: 20px auto;
      span {
        word-break: break-all;
      }
    }
    .number-working {
      width: 35%;
      margin: auto;
    }
  }
  .messageDialog {
    width: 400px;
    height: 900px;
    background: rgba(91, 167, 201, 0.75);
    // background-color: #5BA7C9;
    position: absolute;
    bottom: 170px;
    left: 74%;
    border-radius: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    .message-order {
      box-sizing: border-box;
      width: 380px;
      height: 120px;
      background-color: #b1e8ef;
      opacity: 1;
      margin: 10px auto;
      border-radius: 15px;
      padding: 5px;
    }
    .sign {
      width: 10px;
      height: 10px;
      background-color: #ec808d;
      border-radius: 50%;
    }
    .sign-ms {
      width: 95%;
      .sign-sb {
        color: #000;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .messageDialog::-webkit-scrollbar {
    display: none;
  }
  .workarea::-webkit-scrollbar {
    display: none;
  }

  .bulding-message {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 650px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 20px 0;
    // margin-bottom: 400px;
  }
  .bulding-message::-webkit-scrollbar {
    display: none;
  }
  .baojingDialog {
    width: 500px;
    height: 700px;
    background: rgba(91, 167, 201, 0.75);
    position: absolute;
    bottom: 18px;
    border-radius: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    .message-order {
      box-sizing: border-box;
      width: 480px;
      height: 120px;
      background-color: #b1e8ef;
      opacity: 1;
      margin: 10px auto;
      border-radius: 15px;
      padding: 5px;
    }
    .sign {
      width: 10px;
      height: 10px;
      background-color: #ec808d;
      border-radius: 50%;
    }
    .sign-ms {
      width: 95%;
      .sign-sb {
        color: #000;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .baojingDialog::-webkit-scrollbar {
    display: none; //谷歌隐藏滚动条
  }
  ::v-deep .el-dialog__wrapper .el-dialog {
    background-color: #8dcdd6;
    border-radius: 15px;
  }
  ::v-deep .el-dialog__wrapper .el-table,
  .el-table__expanded-cell {
    background-color: #8dcdd6;
  }
  .logo {
    text-align: right;
  }
  .math-a {
    width: 26px;
    height: 26px;
    background-color: #e56c21;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
}
.full-screen {
  position: absolute;
  top: 30px;
  right: 25px;
  width: 26px;
  height: 26px;
  background: url("~@/assets/full-screen2.png") no-repeat center;
  background-size: cover;
  cursor: pointer;
}
.login-call {
  position: absolute;
  top: 30px;
  right: 60px;
  width: 26px;
  height: 26px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.bottom-carousel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px;
  padding-bottom: 15px;
  .icon {
    width: 100px;
    height: 40px;
    background: url("~@/assets/day-icon.png") no-repeat center;
    background-size: contain;
    margin-right: 20px;
  }
  .lunbo {
    padding: 0 8px 0 0;
    box-sizing: border-box;
    height: 35px; //文本区域的高度
    line-height: 35px; //文本的行间距，与高度相同，则表示一行展示
    text-overflow: ellipsis; //超出内容...展示
    word-break: keep-all; //是否折断文本，keep-all表示不折断
    white-space: nowrap; //不换行展示文本
    overflow: hidden; //超出部分隐藏，与text-overflow配合使用
    flex: 1; //这个是跟左侧的图片组成的一个flex布局
    .carousel-title {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
