<template>
  <div class="monitor">
    <div class="monitor_left">
      <div class="tab">
        <!-- <div @click="tabIndex = 1" class="tab-option" :class="{'tab-option_action': tabIndex === 1}">长者状况</div> -->
        <div
          @click="tabIndex = 2"
          class="tab-option"
          :class="{ 'tab-option_action': tabIndex === 2 }"
        >
          报警列表
        </div>
      </div>
      <div class="monitor_left-main" v-if="tabIndex === 2">
        <div class="monitor_left-main-num">
          <div class="monitor_left-main-num_card" style="background: #92d2d2">
            <div class="name">报警数量统计</div>
            <div class="num">{{ staticData.callTotal }}</div>
          </div>
          <div class="monitor_left-main-num_card" style="background: #9dc914">
            <div class="name">已处理</div>
            <div class="num">{{ staticData.dealedSum }}</div>
          </div>
          <div class="monitor_left-main-num_card" style="background: #e66c21">
            <div class="name">待处理</div>
            <div class="num">{{ staticData.waitDealSum }}</div>
          </div>
        </div>
        <div class="monitor_left-main-table">
          <div class="table-header">
            <div class="title">报警时间</div>
            <div class="title">姓名</div>
            <div class="title">报警设备</div>
            <div class="title">处理结果</div>
          </div>
          <div
            class="table-itme"
            v-for="(item, index) in alarmList"
            :key="index"
          >
            <div class="content">
              {{ item.lastCallTime ? item.lastCallTime.substring(0, 16) : "-" }}
            </div>
            <div class="content">{{ item.customerName }}</div>
            <div class="content">{{ item.deviceName }}</div>
            <div class="content">
              <span v-if="item.status === 0" style="color: #e34f70"
                >未处理</span
              >
              <span v-if="item.status === 1" style="color: #f49a2b"
                >处理中</span
              >
              <span v-if="item.status === 2" style="color: #8bb728"
                >已处理</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="monitor_left-main"
        v-if="tabIndex === 1"
        style="padding: 0; height: calc(100% - 41px)"
      >
        <div class="option-card">
          <div class="option-card_main">
            <div class="option-card_main-left">
              <div class="option-card_main-left-pic">
                <div class="photo">
                  <img class="elderPic" :src="elderzc" alt="" />
                </div>
                <img class="sex" v-if="detail.sex === 1" :src="boyzc" alt="" />
                <img class="sex" v-if="detail.sex === 0" :src="girlzc" alt="" />
              </div>
            </div>
            <div class="option-card_main-right">
              <div class="option-card_main-right-content">
                <div
                  class="text"
                  style="
                    font-weight: bolder;
                    font-size: 16px;
                    margin-bottom: 10px;
                    color: #fff;
                  "
                >
                  {{ detail.name }}&nbsp&nbsp&nbsp&nbsp{{ detail.age }}岁
                </div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">手机号：{{ detail.phone }}</div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">护理等级：{{ detail.nurselevel }}</div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">地址：{{ detail.address }}</div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">出生日期：{{ detail.birthday }}</div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">监护人：{{ detail.guardian }}</div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">监护人电话：{{ detail.guardianPhone }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="alarm-process">
          <div class="alarm-process-title">报警处理</div>
          <div class="alarm-process-main">
            <el-collapse v-model="activeNames" accordion>
              <el-collapse-item
                v-for="(item, index) in processList"
                :key="index"
                :name="index"
              >
                <template slot="title">
                  <div class="title">
                    <div style="margin-right: 10px">{{ item.alarmTime }}</div>
                    <div style="margin-right: 10px">{{ item.alarmType }}</div>
                    <div>
                      <span
                        v-if="item.state === 0"
                        style="color: rgb(227, 79, 112)"
                        >未处理</span
                      >
                      <span
                        v-if="item.state === 1"
                        style="color: rgb(139, 183, 40)"
                        >已处理</span
                      >
                    </div>
                  </div>
                </template>
                <div class="detail">
                  <div class="text">报警时间：{{ item.lastCallTime }}</div>
                  <div class="text">报警设备：{{ item.alarmType }}</div>
                  <div class="text">处理方式：{{ item.processMode }}</div>
                  <div class="text">处理结果：{{ item.processResult }}</div>
                  <div class="text">处理人：{{ item.handler }}</div>
                  <div class="text">处理时间：{{ item.handlTime }}</div>
                  <div class="time-line">
                    <el-timeline>
                      <el-timeline-item
                        v-for="(activity, i) in item.activities"
                        :key="i + 'a'"
                        color="#39C0FF"
                        :timestamp="activity.timestamp"
                      >
                        <div v-if="i === 2">
                          <span style="color: #39c0ff">处理完成</span>
                        </div>
                        <div>
                          <span style="color: #39c0ff">{{
                            activity.content
                          }}</span>
                        </div>
                      </el-timeline-item>
                    </el-timeline>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="monitor_right">
      <!-- 地图 -->
      <div id="mapContainer"></div>
      <div id="dialog" class="detail" v-if="detailDialog">
        <div class="option-card" :class="{'option-card-active': detail.calling === true}">
          <div class="option-card_header">
            <div>
              {{ detail.streetName }}{{ detail.streetCommunityName
              }}{{ detail.floorNo }}
            </div>
            <div class="close" @click="detailDialog = false"></div>
          </div>
          <div class="option-card_main">
            <div class="option-card_main-left">
              <div class="option-card_main-left-pic">
                <div class="photo">
                  <img class="elderPic" :src="detail.pic" alt="" />
                </div>
                <img class="sex" v-if="detail.sex === 1" :src="boyzc" alt="" />
                <img class="sex" v-if="detail.sex === 0" :src="girlzc" alt="" />
              </div>
              <div class="option-card_main-left-name">
                {{ detail.elderName }}&nbsp&nbsp&nbsp<span
                  v-if="detail.elderAge"
                  >{{ detail.elderAge }}岁</span
                >
              </div>
              <div class="option-card_main-left-level">
                {{
                  detail.iotList.length > 0
                    ? detail.iotList[0].nurseLevelName
                    : ""
                }}
              </div>
            </div>
            <div class="option-card_main-right">
              <div class="option-card_main-right-content">
                <div class="text">
                  报警时间：{{
                    detail.iotList.length > 0 ? detail.iotList[0].createdDate : "无"
                  }}
                </div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">
                  报警设备：{{
                    detail.iotList.length > 0 ? detail.iotList[0].callTypeName : "无"
                  }}
                </div>
              </div>
              <!-- <div class="option-card_main-right-content">
                  <div class="text">联系电话：{{detail.iotList[0].phone}}</div>
                </div> -->
              <div class="option-card_main-right-content">
                <div class="text">处理结果：</div>
                <div class="state" v-if="detail.callInfoList.length > 0 && detail.status">
                  <div v-if="detail.callInfoList[0].status === 2" style="color: #34bfa2">
                    已处理
                  </div>
                  <div v-else style="color: #f4526a">
                    未处理
                  </div>
                </div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">监护人：{{ detail.guardian }}</div>
              </div>
              <div class="option-card_main-right-content">
                <div class="text">
                  监护人联系方式：{{ detail.guardianPhone }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="dian1" @click="detailDialog = !detailDialog">
        <div class="name">李卫国</div>
     
      </div> -->
      <!--      <div class="dian3">-->

      <!--      </div>-->
      <!-- <div class="care-box" v-if="tabIndex === 1">
        <div class="care-box-title">智能照护</div>
        <div class="care-box-table">
          <div class="header">
            <div class="title">设备名称</div>
            <div class="title">设备数据</div>
            <div class="title">设备状态</div>
            <div class="title">使用情况</div>
          </div>
          <div class="item" v-for="(item, index) in carelist" :key="index">
            <div class="content">{{item.name}}</div>
            <div class="content">{{item.data}}</div>
            <div class="content">{{item.state}}</div>
            <div class="content">{{item.use}}</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import mapStyleJson from "@/utils/map-style-json.js";
import {
  getCall,
  getBedListByCommunityId,
  getRightEmergencyList,
  getBedListByBranch,
  getStaticData
} from "@/api/branch";
import { downloadUrl } from "@/utils/config";
export default {
  name: "monitor",
  props: {
    communityList: {
      type: Array,
      default: () => [],
    },
    streetId: {
      type: Number,
      default: "",
    },
  },
  watch: {
    streetId: {
      handler: function (val, oldVal) {
        this.getBedList(val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      downloadUrl,
      girlzc: require("@/assets/nv.png"),
      boyzc: require("@/assets/nan.png"),
      elderzc: require("@/assets/elder-pic.png"),
      cdsb: require("@/assets/cdsb.png"),
      tabIndex: 2,
      activeNames: 0,
      alarmList: [],
      carelist: [],
      detailDialog: false,
      detail: {},
      processList: [],
      mapStyleJson,
      mapData: [],
      key: 0,
      staticData: {}, // 统计数据
    };
  },
  mounted() {
    console.log(this.streetId, 999);
    this.getBedList();
    this.getAlarmList();
    var that = this;
    // this.$nextTick(async () => {
    //   that.mspinfo()
    // });
    // setTimeout(() => {
    //    that.mspinfo()
    // }, 2000);
  },
  methods: {
    // 获取全部床位坐标
    async getBedList() {
      const res = await getBedListByBranch({
        page: 1,
        rows: 999,
        streetId: this.streetId,
        getCallInfo: true,
      });
      if (res) {
        console.log(res);
        this.detailDialog = false;
        this.mapData = res.rows;
        this.mspinfo();
      }
    },
    mspinfo() {
      var that = this;
      if (this.myMap) {
        this.myMap.clearOverlays();
        console.log(this.myMap, '------------------------------------');
      } else {
        var map = new BMapGL.Map("mapContainer");
        this.myMap = map;
      }
      let data = this.mapData;
      if (data.length == 0) {
        this.myMap.centerAndZoom('开化县', 12); // 初始化地图,设置中心点坐标和地图级别
        return;
      } 
      this.myMap.centerAndZoom(
        new BMapGL.Point(data[0].longitude, data[0].latitude),
        12
      );
      this.myMap.setHeading(50); //设置地图旋转角度
      this.myMap.setTilt(70); //设置地图的倾斜角度
      this.myMap.setDisplayOptions({
        skyColors: ['rgba(33, 150, 243, 0)','rgba(3, 169, 244, 0.2)']
      })
      this.myMap.setMapStyleV2({ styleJson: mapStyleJson });
      this.myMap.enableScrollWheelZoom(true);
      data.forEach((e, i) => {
        var pt = new BMapGL.Point(e.longitude, e.latitude);
        if (e.calling) {
          var icons = require("../assets/alart.gif");
        } else {
          var icons = require("../assets/biaodian1.png");
        }
        var myIcon = new BMapGL.Icon(
          icons,
          new BMapGL.Size(30, 40), //视窗大小
          {
            imageSize: new BMapGL.Size(30, 40), //显示图标大小(根据实际大小等比例缩放)
            imageOffset: new BMapGL.Size(0, 0), // 图片相对视窗的偏移
          }
        );
        var content = e.roomNo;
        var label = new BMapGL.Label(content, {
          // 创建文本标注
          position: pt,
          offset: new BMapGL.Size(5, 10),
        });
        label.setStyle({
          // 设置label的样式
          display: "none",
          color: "#fff",
          fontSize: "22px",
          backgroundColor: "#fff",
          border: "none",
          padding: "0",
        });
        var maker = new BMapGL.Marker(pt, { icon: myIcon }); // 创建标注
        maker.setLabel(label);
        // 点标记添加点击事件
        // if (e.calling) {
        maker.addEventListener("click", function (event) {
          event = event || window.event;
          console.log(e, 99);
          var x = event.pixel.x || event.originalEvent.layerX;
          var y = event.pixel.y || event.originalEvent.layerY;
          console.log("x:"+x+"; y:"+y);
          that.detailDialog = true;
          // that.$nextTick(() => {
          //   let dialog = document.querySelector("#dialog");
          //   console.log(dialog);
          //   dialog.style.top = y - 100 + "px";
          //   dialog.style.left = x - 440 + "px";
          // })
          // that.$refs.dialog.style.left = x - 200 + "px";
          that.detail = e;
          if (that.detail.headImageId === null) {
            that.$set(
              that.detail,
              "pic",
              require("@/assets/default_headimg.png")
            );
          } else {
            that.$set(
              that.detail,
              "pic",
              `${that.downloadUrl}?id=${that.detail.headImageId}`
            );
          }
          console.log(e, that.detailDialog);
        });
        maker.addEventListener("mouseover", function(e) {
            var label = this.getLabel()
            label.setStyle({ display: "block" });
        });
        maker.addEventListener("mouseout", function(e) {
            var label = this.getLabel()
            label.setStyle({ display: "none" });
        });
        // }
        this.myMap.addOverlay(maker);
        this.myMap.addOverlay(label);
      });
      var bd1 = new BMapGL.Boundary();
      bd1.get("开化县", function (rs) {
        let count = rs.boundaries.length;
        for (let i = 0; i < count; i++) {
          let path = [];
          let str = rs.boundaries[i].replace(" ", "");
          let points = str.split(";");
          for (let j = 0; j < points.length; j++) {
            let lng = points[j].split(",")[0];
            let lat = points[j].split(",")[1];
            path.push(new BMapGL.Point(lng, lat));
          }
          let prism = new BMapGL.Prism(path, 2000, {
            topFillColor: "#5679ea",
            topFillOpacity: 0.6,
            sideFillColor: "#5679ea",
            sideFillOpacity: 0.9,
          });
          that.myMap.addOverlay(prism);

          // 绑定鼠标事件
          var events = ["click", "mouseover", "mouseout"];
          for (let i = 0; i < events.length; i++) {
            prism.addEventListener(events[i], (e) => {
              switch (events[i]) {
                case "click":
                  break;
                // case "mouseover":
                //   e.target.setTopFillColor("#475fab");
                //   e.target.setTopFillOpacity(0.8);
                //   break;
                case "mouseout":
                  e.target.setTopFillColor("#5679ea");
                  e.target.setTopFillOpacity(0.5);
                  break;
              }
            });
          }
        }
      });
    },
    // 根据状态统计数量
    countByState(state) {
      let count = 0;
      this.alarmList.forEach((item) => {
        if (item.status === state) {
          count++;
        }
      });
      return count;
    },
    // 获取紧急处理列表
    getAlarmList() {
      if (this.communityList.length === 0) {
        return;
      }
      getRightEmergencyList({
        page: 1,
        rows: 999,
        branchId: this.$branchId,
        streetId: this.streetId,
        // streetCommunityId: this.communityList[this.activeName].id
      }).then((res) => {
        this.alarmList = res.rows;
        this.alarmList.forEach((item, index) => {
          if (item.headImageId === null) {
            this.$set(
              this.alarmList[index],
              "pic",
              require("@/assets/default_headimg.png")
            );
          } else {
            this.$set(
              this.alarmList[index],
              "pic",
              `${this.downloadUrl}?id=${item.bedBean.headImageId}`
            );
          }

          item.bedBean.iotList.forEach((item_, index_) => {
            if (item_.type === 5) {
              // 床垫
              // this.$set(this.flatRoomList[index], 'mattress', item_.status);
              this.$set(this.alarmList[index], "mattress", item_.onBedStatus);
              this.$set(this.alarmList[index], "HeartRate", item_.heart);
              this.$set(this.alarmList[index], "respiration", item_.breath);
            }
            if (item_.type === 1) {
              // 跌倒
              this.$set(this.alarmList[index], "FallDown", item_.workStatus);
            }
            if (item_.type === 0) {
              // 呼叫
              this.$set(this.alarmList[index], "call", item_.workStatus);
            }
          });
        });
      });
      getStaticData({
        branchId: this.$branchId,
        streetIds: this.streetId,
      }).then(res => {
        console.log(res, '获取统计数据')
        this.staticData = res;
      })
    },
  },
};
</script>

<style scoped lang="scss">
.monitor {
  box-sizing: border-box;
  height: 900px;
  display: flex;
  justify-content: space-between;
  padding: 0 30px 0px;
  &_left {
    flex: 5;
    height: 100%;
    margin-right: 20px;
    .tab {
      display: flex;

      &-option {
        background: rgba(14, 89, 146, 0.4);
        padding: 10px 20px;
        color: #fff;
        font-size: 14px;
        border: 1px solid #071f4f;
        cursor: pointer;
        box-sizing: border-box;
      }

      &-option_action {
        background: #165286;
        border: 1px solid #36b6f3;
      }
    }

    &-main {
      height: calc(100% - 61px);
      background: rgba(14, 89, 146, 0.4);
      padding: 10px;
      overflow: hidden;
      &-num {
        display: flex;
        justify-content: space-between;
        &_card {
          width: 31%;
          border-radius: 4px;

          .name {
            color: #fff;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            padding: 10px 0 0 0;
          }
          .num {
            color: #fff;
            text-align: center;
            font-size: 28px;
            padding: 5px 0;
          }
        }
      }

      &-table {
        height: 100%;
        overflow-y: scroll;
        .table-header {
          display: flex;
          .title {
            flex: 1;
            text-align: center;
            font-size: 14px;
            font-weight: bolder;
            color: #fff;
            padding: 20px 0;
          }
        }
        .table-itme {
          display: flex;
          .content {
            flex: 1;
            text-align: center;
            font-size: 14px;
            color: #fff;
            padding-bottom: 20px;
          }
        }
      }

      .option-card {
        width: 100%;
        border-bottom: 2px solid #0a2252;
        &_main {
          display: flex;
          padding: 25px 20px 10px 20px;

          &-left {
            flex: 3;
            //display: flex;
            //justify-content: center;
            //flex-direction: column;
            &-pic {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              .photo {
                width: 80px;
                height: 80px;
                border-radius: 100%;
                overflow: hidden;

                .elderPic {
                  width: 80px;
                  height: 80px;
                }
              }
              .sex {
                position: absolute;
                bottom: 5px;
                right: 25px;
              }
            }
            &-name {
              color: #fff;
              text-align: center;
              padding-top: 5px;
              font-size: 14px;
            }
            &-level {
              text-align: center;
              padding-top: 5px;
              color: #35bfa4;
              font-size: 14px;
            }
          }
          &-right {
            flex: 7;
            &-content {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              .icon {
                width: 15px;
                height: 15px;
              }
              .state {
                div {
                  padding: 0 5px;
                  color: #fff;
                  font-size: 14px;
                }
              }
              .text {
                color: #97a1af;
                margin-left: 10px;
                font-size: 14px;
              }
            }
          }
        }
      }

      .alarm-process {
        padding: 10px 20px;
        &-title {
          color: #fff;
          font-weight: bolder;
          margin-bottom: 20px;
        }
        &-main {
          .title {
            display: flex;
            color: #97a1af;
          }
          .detail {
            position: relative;
            z-index: 999;
            padding: 10px;
            .text {
              color: #97a1af;
            }
            .time-line {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  &_right {
    flex: 15;
    width: 100%;
    // height: 906px;
    // background: url("~@/assets/ditu1.png") no-repeat;
    // background-size: 100% 100%;
    position: relative;

    .dian1 {
      width: 36px;
      height: 43px;
      background: url("~@/assets/biaodian2.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 48%;
      left: 56.8%;
      cursor: pointer;

      .name {
        color: #ffffff;
        font-size: 14px;
        width: 60px;
        left: -50%;
        text-align: center;
        position: absolute;
        transform: translate(10%, 215%);
      }
    }
    .dian2 {
      width: 36px;
      height: 43px;
      background: url("~@/assets/biaodian1.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      //top: 45.5%;
      //left: 65.8%;

      .name {
        color: #ffffff;
        font-size: 14px;
        width: 60px;
        left: -50%;
        text-align: center;
        position: absolute;
        transform: translate(10%, 215%);
      }
    }
    .dian3 {
      width: 36px;
      height: 43px;
      background: url("~@/assets/biaodian1.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 55.5%;
      left: 60%;
    }

    .care-box {
      background: rgba(14, 89, 146, 0.4);
      padding: 20px;
      position: absolute;
      top: 30px;
      left: 30px;

      &-title {
        color: #fff;
        font-weight: bolder;
        margin-bottom: 20px;
      }

      &-table {
        .header {
          display: flex;

          .title {
            flex: 1;
            font-size: 14px;
            font-weight: bolder;
            color: #fff;
            padding-right: 50px;
          }
        }
        .item {
          display: flex;
          .content {
            flex: 1;
            font-size: 14px;
            color: #97a1af;
            padding-right: 50px;
            padding-top: 10px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-collapse-item__header {
  background-color: transparent !important;
  color: #38b9f7 !important;
  border-bottom: 1px solid #2372aa !important;
}
.el-collapse-item__wrap {
  background-color: transparent !important;
  border-bottom: 1px solid #2372aa !important;
}
.el-collapse {
  border-top: 1px solid #2372aa !important;
  border-bottom: 1px solid #2372aa !important;
}
.el-timeline-item__tail {
  border-left: 2px solid #39c0ff !important;
}
.time-line {
  .el-timeline-item:last-child {
    .el-timeline-item__node {
      border: 1px solid #fff;
      left: -2px !important;
    }
  }
}
.detail {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  .close {
    width: 20px;
    height: 20px;
    background: url("~@/assets/close.png") no-repeat center;
    background-size: contain;
    cursor: pointer;
    opacity: 1;
  }
  .option-card {
    position: absolute;
    min-width: 400px;
    // transform: translate(-101%, -40%);
    background-color: #011f43;
    border-radius: 4px;
    z-index: 999;
    &-active {
      border: 1px solid #ab122f;
      box-shadow: inset 0px 0px 16px 0px #ab122f;
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      color: #fff;
      font-size: 14px;
      border-bottom: 2px solid #13305f;

      &-alarmtype {
        color: #f4516c;
        font-size: 18px;
        text-align: center;
      }
      &-alarmtime {
        color: #a0a0a0;
      }
    }
    &_main {
      display: flex;
      padding: 12px 20px;

      &-left {
        flex: 4;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &-pic {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .photo {
            width: 80px;
            height: 80px;
            border-radius: 100%;
            overflow: hidden;

            .elderPic {
              width: 80px;
              height: 80px;
            }
          }
          .sex {
            position: absolute;
            bottom: 5px;
            right: 30px;
          }
        }
        &-name {
          color: #fff;
          text-align: center;
          padding-top: 5px;
          font-size: 14px;
        }
        &-level {
          text-align: center;
          padding-top: 5px;
          color: #35bfa4;
          font-size: 14px;
        }
      }
      &-right {
        flex: 6;
        &-content {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .icon {
            width: 15px;
            height: 15px;
          }
          .state {
            div {
              padding: 0 5px;
              color: #fff;
              font-size: 14px;
            }
          }
          .text {
            color: #fff;
            margin-left: 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
<style>
#mapContainer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
}
</style>
