<template>
  <section class="marquee-wrap">
      <div class="marquee" ref="marquee" :style="{'animationDuration': duration}">
            <span class="text-item" v-for="(item, index) of data" :key="index" v-html="item">{{item}}</span>
            <span class="text-item" v-for="(item, index) of data" :key="`copy-${index}`" v-html="item">{{item}}</span>
      </div>
  </section>
</template>

<script>
export default {
    name: 'marquee',
    props: {
        /* 跑马灯数据 */
        data: {
            type: Array,
            default: () => []
        },
        /* 跑马灯速度，数值越大速度越快 */
        speed: {
            type: Number,
            default: 50
        }
    },
    data () {
        return {
            duration: 0
        };
    },
    mounted () {
        /* 跑马灯速度，使用跑马灯内容宽度 除以 速度 得到完整跑完一半内容的时间 */
        this.duration = ~~this.$refs.marquee.getBoundingClientRect().width / this.speed +'s';
    }
};
</script>

<style lang="scss" scoped>
.marquee-wrap {
    width: 70%;
    max-width: 1500px;
    height: 40px;
    line-height: 40px;
    position: relative;
    overflow: hidden;
    &:after {
        content: '0';
        opacity: 0;
    }
}

.marquee {
    position: absolute;
    font-size: 0;
    white-space: nowrap;
    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.text-item {
    margin-right: 24px;
    font-size: 18px;
    /* 解决Font Boosting */
    -webkit-text-size-adjust: none;
    // max-height: 999px; //如果上面的依然未解决问题就加上这句吧
}

@keyframes marquee {
    to { transform: translateX(-50%);}
}
.marquee {
    width: 544px;
    padding-left: 100p;
    padding-right: 30px;
    margin: 0 auto 28px;
    color: #fff;
    box-sizing: border-box;
}

</style>