<template>
  <div id="app" :style="{transform: `scale(${scale})`}">
    <router-view/>
  </div>
</template>
<script>
export default {
  data () {
    return {
      scale: 1
    }
  },
  mounted () {
    // this.autofit()
  },
  methods: {
    // autofit () {
    //   this.scale = document.body.clientWidth / 1920
    //   console.log(this.scale, document.body.clientWidth)
    // },
  },
}
</script>
<style lang="scss">
* {
  scrollbar-width: none; // 不显示滚动条，但是仍可以滚动 
}
html, body, #app {
  padding: 0;
  margin: 0;
  background-color: rgba(16, 58, 108);
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar:hover {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #4B5563;
}
</style>